import { queryClient, QueryClientProvider } from "./components/plugins/queryClient";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import React from "react";

//Fontello
import "./fontello/css/fontello.css";
import RoutesApp from "./Routes/RoutesApp";

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <RoutesApp/>
      {/* {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />} */}
    </QueryClientProvider>
  );
}

export default App;
