import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Swal from "sweetalert2";
import Modal from "../layout/Modal";
import * as XLSX from "xlsx";
import Pagination from "../plugins/Pagination";
import Spinner from "../plugins/Spinner";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import DatePicker from "react-datepicker";
import DateFnsUtils from '@date-io/date-fns';
import { es } from "date-fns/locale"; // Importa el locale en español
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
// import es from "date-fns/locale/es";
import Header from "../layout/Header";
import ReactPlayer from "react-player";
import Select from "react-select";
import { upload_s3 } from './UploaderS3';

import { BsSearch, BsFileEarmarkExcelFill, BsFillCheckCircleFill, BsFillInfoCircleFill, BsFillTelephoneFill, BsCheck } from "react-icons/bs";
import { PiChartLineUp, PiUsersThreeFill } from "react-icons/pi";
import { BiSolidHelpCircle } from "react-icons/bi";
import { FaComment, FaCalendarCheck, FaAngleLeft } from "react-icons/fa";
import { FaCalendarDays, FaEllipsisVertical } from "react-icons/fa6";
import { IoMegaphoneOutline } from "react-icons/io5";
import { TiExport } from "react-icons/ti";
import ProgressBar from 'react-animated-progress-bar';


const Campaign = () => {
  //# Estado de los archivos cargados
  const [excelFile, setExcelFile] = useState(null);
  //# Estado para el nombre de la lista importada
  const [nameList, setNameList] = useState("");
  const [repeatedName, setRepeatedName] = useState(false);

  //# Estados del modulo
  const [formStep, setFormStep] = useState(1);
  const [lists, setLists] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [copyTemplates, setCopyTemplates] = useState([]);
  const [content, setContent] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [tableCampaigns, setTableCampaigns] = useState([]);
  const [typeMessages, setTypeMessages] = useState([]);
  const [campaign, setCampaign] = useState({});
  const [inputs, setInputs] = useState("none");
  const [modeImport, setModeImport] = useState("createName");
  const [validation, setValidation] = useState(false);

  //# Estados de plugins (Modales, Spinner...)
  const [importLists, setImportLists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const [mode, setMode] = useState("list");
  const [search, setSearch] = useState("");

  //# Estados para la plantilla seleccionada
  const [templateWhatsapp, setTemplateWhatsapp] = useState(false);
  const [templateSms, setTemplateSms] = useState(true);
  const [templateSelected, setTemplateSelected] = useState([]);
  const [date, setDate] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [numberParams, setNumberParams] = useState([]);
  const [correspondence, setCorrespondence] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [selectedVideo, setSelectedVideo] = useState("");
  const [videoo, setVideoo] = useState("");
  const [documentt, setDocumentt] = useState("");
  const [body, setBody] = useState("");
  const [sizeMax, setSizeMax] = useState(false);
  // const [endBody, setEndBody] = useState("");
  const [isUrlDynamic, setIsUrlDynamic] = useState("");
  const [isUrlDynamic2, setIsUrlDynamic2] = useState("");

  const { page, setPage, byPage, resetPage } = usePage();
  const { urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();

  //Preparando la campaña para enviar
  const [prepareCampaign, setPrepareCampaign] = useState(false);
  const [prepareName, setPrepareName] = useState("");
  const [nameListPrepare, setNameListPrepare] = useState([]);
  const [numberPersons, setNumberPersons] = useState("");
  const [creationDate, setCreationDate] = useState("");

  //# Estado para listar nombres de los campos nuevos como encabezado en la tabla
  const [FieldsNames, setFieldsNames] = useState([]);

  //Modal para programar campaña
  const [modalProgram, setModalProgram] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [dateNull, setDateNull] = useState(false);
  const [dateSend, setDateSend] = useState("");
  const [errorDate, setErrorDate] = useState(false);

  //Leer y validar campos
  const [nameValue, setNameValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [listUsersValue, setListUsersValue] = useState("");
  const [templateValue, setTemplateValue] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const [errorList, setErrorList] = useState(false);
  const [errorTemplate, setErrorTemplate] = useState(false);

  const [templateHeader, setTemplateHeader] = useState(false);
  const [contentHeader, setContentHeader] = useState("");
  const [contentTextHeader, setContentTextHeader] = useState("");

  //#--> Estado para los botones
  const [listButtonsCallToAction, setListButtonsCallToAction] = useState([]);
  const [listButtonsQuickReply, setListButtonsQuickReply] = useState([]);
  const [listButtonsUrlDynamic, setListButtonsUrlDynamic] = useState([]);

  const [registersPopup, setRegistersPopup] = useState(false);
  const [listRegister, setListRegister] = useState([]);

  const [errorMedia, setErrorMedia] = useState(false);

  const [isDark, setIsDark] = useState(false);
  const [percentBar, setPercentBar] = useState("0");
  const [loadingPercentBar, setLoadingPercentBar] = useState(false);
  //# Token
  const token = localStorage.getItem("USER_TOKEN");
  registerLocale("es", es);

  const urlFile = useRef("");
  const nameFile = useRef("");
  const sizeFile = useRef("");
  const templateIdRef = useRef("");
  const prepareList = useRef("");
  const aditionalFieldsDate = useRef("");

  const {
    watch,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "all",
    defaultValues: {
      campaignName: "",
      // messageTypeId: "",
      listId: "",
      templateId: "",
      isProgramCamp: false,
      programDate: "",
      video: "",
      document: "",
      image: "",
      text: "",
      longitud: "",
      latitud: "",
      name: "",
      direccon: ""
    },
  });

  useEffect(() => {
    getCampaigns();
    getCorresp();
    getLists();
    getPersons();
    getTemplates();
    reset();
    getAdditionalFieldsDate();
  }, []);

  const getAdditionalFieldsDate = async () => {
    const companyId = localStorage.getItem("COMPANY_ID");
    const url = urlAPI_2 + "companies/" + companyId;
    const options = { method: "GET", mode: "cors"};
    const response = await fetchWithAuth(url, options);
    const result = await response.json();
    aditionalFieldsDate.current = result.data[0].aditionalFieldsUpdate;
  }
  const getCampaigns = async () => {
    try {
      setLoading(true);
      const url = urlAPI_1 + "campaigns";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Listado de campañas ", result);
      if (result.type != "success") {
        setValidation(true);
        setCampaigns([]);
      } else {
        setValidation(false);
        setCampaigns(result.data);
        console.log("setTableCampaigns: ", result.data);
        setTableCampaigns(result.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getLists = async () => {
    try {
      const url = urlAPI_1 + "lists";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Listas", result);
      if (result.type != "success") {
        setLists([]);
      } else if (
        result.message === "Tu petición no tiene cabecera de autorización"
      ) {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      } else {
        setLists(result.data);
        // setValidation(false);
      }
    } catch (error) {
      console.log("error on actualizar", error);
      return null;
    }
  };

  const getPersons = async () => {
    try {
      const url = urlAPI_1 + `persons`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
        console.log("code 0");
      } else if (!result.data) {
        console.log("no hay data");
      } else {
        console.log("si hay lista:::", result.listFields);
        setFieldsNames(result.listFields);
      }
    } catch (error) {
      console.log("error on actualizar", error);
      return null;
    }
  };

  const getTemplates = async () => {
    try {
      const url = urlAPI_2 + "templates";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Plantillas=========>>", result.data);
      if (result.type != "success") {
        setTemplates([]);
      } else {
        const resTemplates = result.data.filter(res => {
          if(res.status === "APROBADA" && res.isActive === true) return res
        })
        setTemplates(resTemplates);
      }
    } catch (error) {
      console.log("error on actualizar", error);
      return null;
    }
  };


  const getCorresp = async () => {
    try {
      const url = urlAPI_2 + "getDataEmail";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("list correspondence-------->>", result.data);
      let data = result.data;
      if(data.length) {
        data = data.filter(v => v.field !== "callingCode")
      }
      setCorrespondence(data);
      if (result.message === "Tu petición no tiene cabecera de autorización") {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postCampaign = async () => {
    
    try {
      setLoading(true)
      setModalProgram(false);
      const d = numberParams;
      console.log("==============> ", d);

      if(d) {
        var param = [];
        for (let i = 0; i < numberParams.length; i++) {
          const element = numberParams[i];
          param.push(i);
        }
      }

      const {campaignName,listId,templateId,isProgramCamp,programDate,image,video,document,param0,param1,param2,param3,param4,param5,param6,param7,param8,param9,urlParam,urlParam2,latitud,longitud,name,adress} = watch();
      const id = templateIdRef.current;
      let date = "";
      if (programDate !== "") {
        date = new Date(programDate);
      } else {
        date = "";
      }

      if (startDate === null) {
        setDateNull(true);
        var finalDate = "";
      } else {
        var finalDate = startDate;
      }

      var objParamsBody = [];
      if (param0) {
        objParamsBody.push({ type: "text", text: param0, number: `{{1}}` });
      }
      if (param1) {
        objParamsBody.push({ type: "text", text: param1, number: `{{2}}` });
      }
      if (param2) {
        objParamsBody.push({ type: "text", text: param2, number: `{{3}}` });
      }
      if (param3) {
        objParamsBody.push({ type: "text", text: param3, number: `{{4}}` });
      }
      if (param4) {
        objParamsBody.push({ type: "text", text: param4, number: `{{5}}` });
      }
      if (param5) {
        objParamsBody.push({ type: "text", text: param5, number: `{{6}}` });
      }
      if (param6) {
        objParamsBody.push({ type: "text", text: param6, number: `{{7}}` });
      }
      if (param7) {
        objParamsBody.push({ type: "text", text: param7, number: `{{8}}` });
      }
      if (param8) {
        objParamsBody.push({ type: "text", text: param8, number: `{{9}}` });
      }
      if (param9) {
        objParamsBody.push({ type: "text", text: param9, number: `{{10}}` });
      }

      var objParamsMedia = {};
      let data_media = {urlFile: urlFile.current, nameFile: nameFile.current, sizeFile: sizeFile.current}
      if (selectedImage) {
        objParamsMedia = { image: data_media };
      } else if (selectedVideo) {
        objParamsMedia = { video: data_media };
      } else if (selectedDocument) {
        objParamsMedia = {
          document: data_media,
        };
      }

      var varUrl = "";
      if (isUrlDynamic) {
        varUrl = urlParam;
      } else if (isUrlDynamic2) {
        varUrl = urlParam2;
      }

      const values = {
        campaignName,
        // messageTypeId,
        listId,
        templateId: id,
        isProgramCamp: finalDate,
        programDate: finalDate,
        parametersMedia: objParamsMedia,
        parametersBody: objParamsBody,
        parameterUrl: varUrl,
      };

      if(!listId){
        Swal.fire({
          title: "La lista no puede ser inexistente",
          text: "Hubo un error al obtener el ID de la lista",
          icon: "error",
        });
        return;
      }

      console.log("Enviando datos........", values);
      
      const url = urlAPI_1 + "saveCampaign";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(values)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("result........", result);
      setLoading(false);

      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setMode("list");
          getCampaigns();
        }
      })
    
      
      setTemplateWhatsapp(false);
      setTemplateSms(false);
      setTemplateSelected([]);
      setSelectedVideo("");
      setSelectedImage("");
      setSelectedDocument("");
      setNumberParams([]);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setTemplateWhatsapp(false);
      setTemplateSms(false);
      setTemplateSelected([]);
      setSelectedVideo("");
      setSelectedImage("");
      setSelectedDocument("");
      setNumberParams([]);
    }
  };

  const postList = async (array) => {
    const values = {
      nameList,
      dataUsers: array,
    };
    console.log("nameList: ", values.nameList);
    console.log("dataUsers: ", values.dataUsers);
    let total = values.dataUsers.length;
    let processCode = "";
    try {
      setImportLists(false);
      setLoading(true);
      const url = urlAPI_1 + "persons/importList";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(values)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      processCode = result.processCode
      setLoading(false);
  
      if (response.ok) {
        setNameList("");
        let successSwal = {};
        if(result.message == "Todos los registros contienen errores"){
          successSwal = {
            title: "La lista no pudo ser cargada",
            text: "No se pudo cargar ningún contacto de su lista",
            icon: "error",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'OK'
          };
        }else{
          successSwal = {
            title: result.message,
            text: "Aguarde unos instantes hasta su confirmación, la lista está siendo procesada. Por favor no reinice ni salga de esta pantalla",
            icon: result.type,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'OK'
          };
        }
      
        if (result.contactosError && result.contactosError.length > 0) {
          if(result.message == "Todos los registros contienen errores"){
            successSwal.text = 'No se pudo cargar ningún contacto de su lista. Puedes descargar el excel para ver los detalles.';
          }else{
            successSwal.text = 'Algunos contactos dieron error y puedes descargar el excel para ver los detalles. El resto está siendo procesado. Por favor no reinicie el navegador, aguarde hasta su confirmación.';
          }
          total = total - result.contactosError.length;
          successSwal.showConfirmButton = true;
          successSwal.confirmButtonText = 'Descargar Errores';
          successSwal.preConfirm = () => {
            const errorWorkbook = XLSX.utils.book_new();
            const errorWorksheet = XLSX.utils.json_to_sheet(result.contactosError, { skipHeader: true });
            XLSX.utils.book_append_sheet(errorWorkbook, errorWorksheet, 'Errores');
            const errorWbout = XLSX.write(errorWorkbook, { bookType: 'xlsx', type: 'array' });
            const errorBlob = new Blob([errorWbout], { type: 'application/octet-stream' });
            const errorFileName = 'Errores_Importacion_Lista.xlsx';
      
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(errorBlob);
            a.href = url;
            a.download = errorFileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          };
        }
        
        Swal.fire(successSwal).then((result2) => {
          if(result.message == "Todos los registros contienen errores"){
            return;
          }
      
          let count_limit = 50;
          setLoadingPercentBar(true);
          // Hacer verificaciones periódicas
          const checkImportList = async () => {
            count_limit -= 1;
            if(!count_limit){
              Swal.fire({
                title: "Error al verificar la importación.",
                text: "Hubo un problema al comprobar el estado de la importación.",
                icon: "error",
              });
              return;
            }
            const checkUrl = urlAPI_1 + `importListCheck/${processCode}?nameList=${values.nameList}`;
            const options = { method: "GET", mode: "cors"};
            const checkResponse = await fetchWithAuth(checkUrl, options);
            if (checkResponse.ok) {
              const checkResult = await checkResponse.json();
              console.log("checkResult: ", checkResult);
              let process = checkResult.process;
              let percent = 0;
              if(checkResult.total){
                percent = checkResult.total * 100 / total;
                percent = Math.round(percent);
              }
              if(percent >= 100){
                percent = 99;
              }
              setPercentBar(percent.toString());
              if (process.status == "completed") {
                Swal.close();
                const { campaignName } = watch();
                reset({ campaignName, listId: checkResult.list._id });          
                getLists();
                setFormStep(3);
                Swal.fire({
                  title: "Lista importada exitosamente",
                  text: "La lista ha sido importada de manera exitosa",
                  icon: "success",
                });
                setLoadingPercentBar(false);              
              } else if(process.status == "pending") {
                // Esperar 5 segundos y verificar nuevamente
                setTimeout(checkImportList, 2000);
              } else {
                Swal.fire({
                  title: "Importación fallida",
                  text: "Hubo un error inesperado al importar los contactos.",
                  icon: "error",
                });
                setLoadingPercentBar(false);
              } 
            } else {
              // Manejar errores en la verificación
              Swal.fire({
                title: "Error al verificar la importación.",
                text: "Hubo un problema al comprobar el estado de la importación.",
                icon: "error",
              });
              setLoadingPercentBar(false);
            }
          };

          // Comenzar a verificar después de 5 segundos
          setTimeout(checkImportList, 2000);
        });
  
      } else {
        Swal.fire({
          title: "Error en la importación.",
          text: result.message || "Hubo un error al procesar la solicitud.",
          icon: "error",
        });
        setLoadingPercentBar(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      // Mostrar mensaje de error
      setLoading(false);
      setLoadingPercentBar(false);
      Swal.fire({
        title: "Error en la importación.",
        text: "Hubo un error al procesar la solicitud.",
        icon: "error",
      });
    }
  
  };

  //# Función que ayuda a cargar y validar la importación de archivos en formato Excel
  const uploadFile = (e) => {
    //# Definiendo el tipo de archivo permitido
    const fileType = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFile(null);
        Swal.fire({
          title: "Error al cargar los datos",
          text: "Por favor elige el formato correcto.",
          icon: "info",
        });
      }
    }
  };

  const sendFile = () => {
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];

      //#--> Obtenemos las llaves del objeto que nos genera worksheet
      const allKeys = Object.keys(worksheet);
      
      //#--> Ordenamos alfabeticamente las llaves
      const sortKeys = allKeys.sort()
      
      //#--> Obtenemos el ultimo valor
      const lastValue = sortKeys[sortKeys.length - 1]

      //#--> Obtenemos solamente la letra del ultimo valor
      const letra = lastValue.replace(/\d/g, '');
      
      // const ultimaLetra = letra.charAt(letra.length - 1);
      
      //#--> Obtenemos solamente la letra del ultimo valor
      const numericalValue = letra.charCodeAt(0) - 'A'.charCodeAt(0) + 1;

      //#--> Obtener encabezados directamente de las columnas A a la ultima letra
      const headers = Array.from({ length: numericalValue }, (_, index) => String.fromCharCode(65 + index) + '1');
      console.log("headers=====>>", headers);

      //#--> Validar si algún encabezado está vacío
      const emptyHeaders = headers.filter(header => {
        const cellValue = worksheet[header] ? worksheet[header].v : null;
        return cellValue === undefined || cellValue === null || cellValue === '';
      });
      console.log("emptyHeaders=====>>", emptyHeaders);
      
      if (emptyHeaders.length > 0) {
        setImportLists(false)
        setExcelFile(null);
        //#--> Validamos si es mas de un encabezado para ajustar el mensaje
        let text = ""
        if (emptyHeaders.length > 1) {
          text =  `Los encabezados ${emptyHeaders.join(', ')} no pueden estar vacíos.`
        } else {
          text = `El encabezado ${emptyHeaders.join(', ')} no puede estar vacío.`
        }
        Swal.fire({
          title: "¡Atención!",
            text: text,
            icon: "error",
        });
      } else {
          //#--> Todos los encabezados están llenos
          const data = XLSX.utils.sheet_to_json(worksheet);
          validateAndImport(data);
          setExcelFile(null);
      }
    } else {
      Swal.fire({
        title: "¡Atención!",
        text: "No hay ningun archivo seleccionado, por favor selecciona el archivo a importar.",
        icon: "info",
      });
    }
  };

  const validateAndImport = async (array) => {
    let isValid = true;
    const emptyCells = [];
    const emailRegex = /^\S+@\S+\.\S+/;
    const requiredColumns = ["Nombre", "Código de area", "Teléfono"];
  
    for (let i = 0; i < array.length; i++) {
      const data = array[i];
      const keywords = Object.keys(data);
      
      if (requiredColumns.every((column) => keywords.includes(column))) {
        let errorMessages = [];
        
        //#--> Validar formato de correo
        const correo = data["Correo"];
        if (correo) {
          if (!emailRegex.test(correo)) {
            errorMessages.push("Correo");
            isValid = false;
          }
        }

        //#--> Validar si Código de área es numérico
        const codigoArea = data["Código de area"];
        if (isNaN(codigoArea)) {
          errorMessages.push("Código de área");
          isValid = false;
        }

        //#--> Validar si Teléfono es numérico
        const telefono = data["Teléfono"];
        if (isNaN(telefono)) {
          errorMessages.push("Teléfono");
          isValid = false;
        }

        if (errorMessages.length > 0) {
          const msgFormat = errorMessages.length > 1 ? errorMessages .slice(0, -1).join(", ") + " y " + errorMessages[errorMessages.length - 1] : errorMessages.join(", ");
          emptyCells.push({
            linea: i + 1,
            callingCode: data["Código de area"],
            phone: data.Teléfono,
            columnas: `Formato incorrecto en: ${msgFormat}`,
          });
        }
        
      } else {
        isValid = false;
        const missingColumns = requiredColumns.filter((column) => !keywords.includes(column));
        const text = missingColumns.length > 1 ? "Campos vaciós:" : "Campo vacio:" ;
        const msgFields = missingColumns.length > 1 ? missingColumns .slice(0, -1).join(", ") + " y " + missingColumns[missingColumns.length - 1] : missingColumns.join(", ");
        emptyCells.push({
          linea: i + 1,
          callingCode: data["Código de area"],
          phone: data.Teléfono,
          columnas: `${text} ${msgFields}`,
        });
      }
    }
    postList(array);
    setNameList("");
    setModeImport("createName");
  };

  const editCampaign = async (inputs) => {
    const id = campaign._id;
    const {
      campaignName,
      // messageTypeId,
      listId,
      templateId,
      isProgramCamp,
      programDate,
    } = inputs;
    // const type = parseInt(messageTypeId);
    const idTemplate = templateId.split("|||");
    const template_id = idTemplate[1];
    const values = {
      campaignName,
      // messageTypeId: type,
      listId,
      templateId: template_id,
      isProgramCamp,
      programDate,
    };
    try {
      setLoading(true);
      const url = urlAPI_1 + `campaigns/${id}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(values)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      // console.log("Data Updated", result);

      //# PUT Completado, se reinician lo campos
      getCampaigns();
      setMode("list");
      setInputs("none");
      setFormStep(1);
      reset({
        campaignName: "",
        // messageTypeId: "",
        listId: "",
        templateId: "",
        isProgramCamp: false,
        programDate: "",
      });
      setLoading(false);

      //# Confirmacion del cambio
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCampaign = async (id) => {
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar esta campaña?",
        text: "No podrás tener acceso a su preparación.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const url = urlAPI_1 + `campaigns/${id}`;
          const options = { method: "DELETE", mode: "cors"};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();
          // console.log("Data Eliminated", result);

          //# Actualización de la tabla
          getCampaigns();
          setLoading(false);

          //# Confirmación de la eliminación
          Swal.fire({
            title: "¡Bien!",
            text: result.message,
            icon: result.type,
            confirmButtonText: "Aceptar",
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const searchQuery = (searchTerm) => {
    const searchResults = tableCampaigns.filter((element) => {
      if (
        element.nameCampaign
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        element.template
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        element.list
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        element.type
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        element.status
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        return element;
      }
    });
    setCampaigns(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  //#--> Cuando seleccionamos una plantilla ...................
  const handleChange = (event) => {
    setSelectedImage((selectedImage) => { return "" });
    setSelectedVideo((selectedVideo) => { return "" });
    setSelectedDocument((selectedDocument) => { return "" });
    setNumberParams([]);
    setIsUrlDynamic("");
    setIsUrlDynamic2("");
    setListButtonsCallToAction([]);
    setListButtonsQuickReply([]);
    setTemplateSelected([]);
    setListButtonsUrlDynamic([])
    console.log('Template Selected: ', event );

    let template = [event]
    setTemplateSelected(template);

    //#--> Validamos que si haya una plantilla selecionada
    // if (selected[0]) {
    if (template[0]) {
      templateIdRef.current = template[0].id;
      //#--> Validamos que si tenga header la plantilla selecionada
      const filterHeader = template[0].content.filter(tem => {if (tem.type === "HEADER") return tem})
      console.log('filterHeader==============>', filterHeader);
      if (filterHeader.length != 0) {
        setTemplateWhatsapp(true);
        setTemplateHeader(true);
        setContentHeader(filterHeader[0].format)
        setContentTextHeader(filterHeader[0].text)
      } else {
        setTemplateWhatsapp(false);
        setTemplateHeader(false);
        setContentHeader("");
      }


      //#--> Validamos que si tenga Body la plantilla selecionada
      const filterBody = template[0].content.filter(tem => {if (tem.type === "BODY") return tem})
      const bod = filterBody[0].text;
      console.log('-----Valor del body------->', bod)
      setBody(bod);
      
      //#--> validamos si el contenido tiene parametros      
      const params = bod.match(/{{\d+}}/g);
      setNumberParams(params);
      console.log('-----Valor del params------->', params)
      localStorage.setItem("N_PARAMS", params)


      //#--> Validamos que si tenga botones la plantilla selecionada
      const filterButtons = template[0].content.filter(tem => {if (tem.type === "BUTTONS") return tem});
      console.log('filterButtons ===>', filterButtons); 

      if (filterButtons.length != 0) {
        const listButtons = filterButtons[0].buttons;
        console.log('lista de botones ===>', listButtons);

        var listButtonsCallToAction = [];
        var listButtonsQuickReply = [];
        var listButtonsUrlDynamic = [];
        for (let b = 0; b < listButtons.length; b++) {
          const element = listButtons[b];
          
          if (element.type == "URL") {
            listButtonsCallToAction.push(element);

            if (element.example) {
              listButtonsUrlDynamic.push(element);
              setIsUrlDynamic(true);
            }
            
          } else if (element.type == "PHONE_NUMBER") {
            listButtonsCallToAction.push(element);
            
          } else if (element.type == "QUICK_REPLY") {
            listButtonsQuickReply.push(element);
            
          }
        }
        console.log('listButtonsCallToAction==>', listButtonsCallToAction);
        console.log('listButtonsQuickReply==>', listButtonsQuickReply);
        console.log('listButtonsUrlDynamic==>', listButtonsUrlDynamic);

        setListButtonsCallToAction(listButtonsCallToAction);
        setListButtonsQuickReply(listButtonsQuickReply);
        setListButtonsUrlDynamic(listButtonsUrlDynamic);

      }
    }
    //#--> Sacamos la hora del momento para pintar en la previsualización
    const today = new Date();
    var hours = today.getHours();
    var minutes = today.getMinutes();
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    var time = hours + ":" + minutes;
    setDate(time);
  };

  //Convertit imagen en base64
  const onFile = async (e) => {
    var file = e.target.files[0];
    if (file) {
      setErrorMedia(false)
    }
    console.log('========> ',file);
    var nameI = e.target.files[0].name;
    var base64 = await convertBase64(file);
    setSelectedImage(base64);
    // reset({image: file})
    await uploadToS3(file);
  };

  //Convertit imagen en base64
  const convertBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };
  //Convertir documento en base64
  const onFileDocument = async (e) => {
    var fileDocument = e.target.files[0];
    if (fileDocument) {
      setErrorMedia(false)
    }
    setDocumentt(URL.createObjectURL(e.target.files[0]));
    var nameD = e.target.files[0].name;
    var base64 = await convertDocumentBase64(fileDocument);
    setSelectedDocument(base64);
    await uploadToS3(fileDocument);
  };

  //Convertir documento en base64
  const convertDocumentBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };
  //Convertir video en base64
  const onFileVideo = async (e) => {
    var fileDocument = e.target.files[0];
    if (fileDocument) {
      setErrorMedia(false)
    }
    console.log("archivo cargado.....", fileDocument);
    setVideoo(URL.createObjectURL(e.target.files[0]));
    console.log("url del video: ", videoo);
    console.log("url 2.....", URL.createObjectURL(e.target.files[0]));
    var base64 = await convertVideoBase64(fileDocument);
    setSelectedVideo(base64);
    await uploadToS3(fileDocument);
  };
  //Convertir video en base64
  const convertVideoBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  const getSignedUrl = async (key_name, size) => {
      try {
        key_name = encodeURIComponent(key_name);
        const url = urlAPI_1 + `getSignedURL/${key_name}/${size}`;
        const options = { method: "GET", mode: "cors"};
        const response = await fetchWithAuth(url, options);
        if(response.status == 200){
            let url_signed = await response.json();
            if(url_signed.error){
                return false;
            }
            return url_signed;
        }else{
            console.error('Error getting signed URL');
            return false;
        }
        return response;
      } catch (error) {
        console.error('Error getting signed URL:', error);
        return false;
      }
  };

  const uploadToS3 = async (file) => {
      try {
        let max_size = false;
        if(file.type.includes("audio") && file.size > ((1024 * 1024) * 16)){
          max_size = true;
          Swal.fire({
            title: "¡Atención!",
            text: "El archivo supera el peso máximo permitido para audio: 16MB. Intente enviarlo como documento.",
            icon: "info",
          });
        }
        if(file.type.includes("video") && file.size > ((1024 * 1024) * 16)){
          max_size = true;
          Swal.fire({
            title: "¡Atención!",
            text: "El archivo supera el peso máximo permitido para video: 16MB. Intente enviarlo como documento.",
            icon: "info",
          });
        }
        if(file.type.includes("image") && file.size > ((1024 * 1024) * 5)){
          max_size = true;
          Swal.fire({
            title: "¡Atención!",
            text: "El archivo supera el peso máximo permitido para imagen: 5MB. Intente enviarlo como documento.",
            icon: "info",
          });
        }
        if(file.type.includes("document") && file.size > ((1024 * 1024) * 100)){
          max_size = true;
          Swal.fire({
            title: "¡Atención!",
            text: "El archivo supera el peso máximo permitido para documentos: 100MB. Intente enviarlo como documento.",
            icon: "info",
          });
        }

        if (max_size) {
          setSizeMax(true);
          setSelectedImage((selectedImage) => { return "" });
          setSelectedVideo((selectedVideo) => { return "" });
          setSelectedDocument((selectedDocument) => { return "" });
          return false;
        } else {
          setSizeMax(false);
        }
        setLoading(true);
        const signedUrl = await getSignedUrl(file.name, file.size);
        if(signedUrl){
          urlFile.current = await upload_s3(file, signedUrl);
          nameFile.current = file.name;
          sizeFile.current = file.size;
          console.log('urlFile.current::', urlFile.current);
          console.log('nameFile.current::', nameFile.current);
          console.log('file.type::', file.type);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error uploading to s3...', error);
        return false;
      }
  };

  //#--> Función para descargar plantilla en xlsx
  const downloadTable = () => {
    const table = document.getElementById('tablePersonsCampaign');
    const worksheet = XLSX.utils.table_to_sheet(table);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Lista de usuarios');
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const fileName = 'Plantilla sendingup.xlsx';

    // Descargar archivo XLSX
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const onPrepareCampaign = () => {
    console.log("Preparar campaña....")
    if (sizeMax) {
      Swal.fire({
        title: "¡Atención!",
        text: "El video supera el peso máximo permitido",
        icon: "info",
      });
    } else {
      setPrepareCampaign(true);
      setFormStep(0);
      console.log('-------prepareList--------', prepareList.current);
      console.log('formatList: ', formatList);
      let filterList = formatList.filter((li) => li.name == prepareList.current);
      let name_Lists = filterList[0].name;
      console.log('name_Lists: ', name_Lists);
      let num_Persons = filterList[0].count;
      setNameListPrepare(name_Lists);
      setNumberPersons(num_Persons);

      let date = new Date();
      const newFech = dayjs(date)
        .locale("es")
        .format("DD MMMM YYYY");
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let newformat = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      const finalDate = `${newFech} - ${hours}:${minutes} ${newformat}`;
      setCreationDate(finalDate);
    }
  }

  // Función para manejar los cambios en los select de los parametros
  const onParams = (e, index) => {
    console.log('--------e-------->', e.target.value)
    console.log('--------index-------->', index)
    const selectedValue = e.target.value;
    
    setNumberParams((prevParams) => {
      const updatedParams = [...prevParams];
      console.log('--------updatedParams----1---->', updatedParams)
      updatedParams[index] = selectedValue;
      console.log('--------updatedParams----2---->', updatedParams)
      
      const updatedBody = updatedParams.reduce((body, param, k) => {
        // console.log('--------body-------->', body)
        // console.log('--------param-------->', param)
        var field = ""
        if (param === "email") {
          field = "Correo usuario"
        } else if (param === "name") {
          field = "Nombre usuario"
        } else if (param === "phone") {
          field = "Teléfono usuario"
        } else if (param.includes("{{")) {
          field = param
        } else {
          field = `${param} usuario`
        }
        const placeholder = `{{${k + 1}}}`;
        return body.replaceAll(placeholder, field);
      }, body);
      
      console.log('--------updatedBody-------->', updatedBody)
      setBody(updatedBody);
      return updatedParams;
    });
  };
  

  const { isProgramCamp } = watch();
  const formatCampaign = [...campaigns].reverse();
  const formatList = [...lists].reverse();
  const navigate = useNavigate();

  useEffect(() => {
    if (isProgramCamp === false) {
      // const { campaignName, messageTypeId, listId, templateId } = watch();
      const { campaignName, listId, templateId } = watch();
      reset({
        campaignName,
        // messageTypeId,
        listId,
        templateId,
        isProgramCamp: false,
        programDate: "",
      });
    }
  }, [isProgramCamp]);

  //#--> Metodos para ajustar color del select de listas 
  const getIsDark = () => {
    const dark = localStorage.getItem("THEME");
    setIsDark(dark);
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
  };

  useEffect(() => {
    console.log('🌞🌞🌞🌞🌞🌞', errors.campaignName);
  }, [errors.campaignName]);
  

  const customStylesDark = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      {/*-------- popup - tabla registros con error ----------*/}
      {registersPopup && (
        <div className="image-preview-overlay">
          <div className="image-preview-container">
            <div className="records-not-loaded p-2" style={{width: "1000px", height: "auto", borderRadius: "7px"}}>
                <div className="p-3">
                  <h2 className="mb-3 d-flex justify-content-center" >
                    Registros no cargados!
                  </h2>
                  <div className="container-fluid mt-3 px-0" style={{ height: "580px", overflow: "auto" }}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Línea</th>
                          <th>Código de area</th>
                          <th>Contacto</th>
                          <th>Descripción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listRegister && listRegister.map((list, l) => (
                          <tr key={l}>
                            <td>{list.linea}</td>
                            <td>{list.callingCode}</td>
                            <td>{list.phone}</td>
                            <td>{list.columnas}</td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <a
                      href="javascript:;"
                      className="btn btn-lg btn-default mr-1"
                      onClick={() => setRegistersPopup(false)}
                      >
                      Cerrar
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      )}

      {mode === "list" && (
        <>
          <div className="m-auto container-fluid p-0 h-100">
            <div className="col-xs-9 heading">
              <div className="mt-2">
                <h1>Campañas</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Agregar Nueva"
                  onClick={() => {
                    setMode("create");
                    setCampaign({});
                    setFormStep(1);
                    setStartDate(null);
                    setTemplateSms(false);
                    setTemplateSelected([]);
                    setPrepareCampaign(false);
                    setTemplateWhatsapp(false);
                    setListButtonsUrlDynamic([]);
                    reset({
                      campaignName: "",
                      // messageTypeId: "",
                      listId: "",
                      templateId: "",
                      isProgramCamp: "",
                      programDate: "",
                    });
                  }}
                />
              </div>

              <Header />
            </div>

            <header>
              <div style={{ float: "right" }}>
                <form className="form-inline">
                  <div className="su-control my-1">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Búsqueda"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        searchQuery(e.target.value);
                        resetPage(1);
                      }}
                    />
                    <button
                      className="btn btn-info"
                      type="button"
                      id="buttonSearchProductBack"
                      style={{ height: "34px" }}
                    >
                      <BsSearch />
                    </button>
                  </div>
                </form>
              </div>
            </header>

            <div className="container-fluid mt-3 px-0" style={{ height:"75%", overflowY:"auto", overflowX:"none" }}>
              <table className="table table-striped table-campaigns">
                <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
                  <tr>
                    <th>Acciones</th>
                    <th>Nombre</th>
                    <th>Lista</th>
                    <th>Total enviados</th>
                    <th>Plantilla</th>
                    <th>Estado</th>
                    {/* <th>Tipo</th> */}
                    <th>Creación</th>
                  </tr>
                </thead>
                <tbody>
                  {formatCampaign.length > 0 && formatCampaign.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((campaign) => (
                    <tr key={campaign._id}>
                      <td>
                        <div className="row justify-content-around">
                          <div className="dropdown">
                            <button
                              className="bg-transparent border-0"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <FaEllipsisVertical className="text-success f-24 pointer" />
                            </button>
                            <div
                              className="dropdown-menu p-2"
                              aria-labelledby="dropdownMenuButton"
                            >
                              {campaign.status != "pending" ? (
                                <button
                                  onClick={() => {
                                    navigate(
                                      `/home/campaignReport/${campaign._id}`
                                    );
                                  }}
                                  className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                >
                                  <PiChartLineUp style={{fontSize: "20px",color: "#00c900"}}/>
                                  <span className="text-dark ml-2 f-14">
                                    Ver reporte
                                  </span>
                                </button>
                              ) : null} 
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        {/* {campaign.nameCampaign} */}
                        {campaign.nameCampaign.length >= 30
                          ? campaign.nameCampaign.substr(0, 30) + "..."
                          : campaign.nameCampaign}
                      </td>
                      <td>
                        <Link
                          className="hover-a"
                          to={`/home/list/${campaign.listId}`}
                        >
                          {/* {campaign.list} */}
                          {campaign.list.length >= 27
                            ? campaign.list.substr(0, 27) + "..."
                            : campaign.list}
                        </Link>
                      </td>
                      <td>{campaign.totalContacts}</td>
                      <td>
                        <Link
                          className="hover-a"
                          to={
                            campaign.type === "6286784ad0f2008a00503992"
                              ? `/home/templateWhatsappEdit/${campaign.templateId}`
                              : `/home/templates/`
                            // : `/home/template/${campaign.templateId}`
                          }
                        >
                          <strong>
                            {campaign.template.length >= 24
                              ? campaign.template.substr(0, 24) + "..."
                              : campaign.template}
                          </strong>
                        </Link>
                      </td>
                      <td className="status-campaign">
                        <p>
                          <a
                            href="javascript:;"
                            className={
                              campaign.status === "pending"
                                ? "gray"
                                : campaign.status === "complete"
                                ? "green"
                                : campaign.status === "processing"
                                ? "gray"
                                : campaign.status === "error"
                                ? "red"
                                : null
                            }
                          >
                            {campaign.status === "pending"
                              ? "Pendiente"
                              : campaign.status === "complete"
                              ? "Enviada"
                              : campaign.status === "processing"
                              ? "Procesando"
                              : campaign.status === "error"
                              ? "Error"
                              : null}
                          </a>
                        </p>
                      </td>
                      {/* <td>
                        {
                          <div className="row justify-content-center mr-0 ml-0">
                            <div className="dropdown">
                              <BsWhatsapp style={{color: "#25D366",fontSize: "23px"}}/>
                            </div>
                          </div>
                        }
                      </td> */}
                      <td>
                        Creada el{" "}
                        {dayjs(campaign.created)
                          .locale("es")
                          .format("DD MMMM YYYY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {validation && (
                <div className="text-center mt-3">
                  <div className="empty">
                    <IoMegaphoneOutline style={{ fontSize: "55px" }}/>
                    <h1>No tienes campañas</h1>
                    <input
                      type="button"
                      className="btn btn-default"
                      value="Agregar Nueva"
                      onClick={() => {
                        setMode("create");
                        setCampaign({});
                        setFormStep(1);
                        setStartDate(null);
                        setTemplateSms(false);
                        setTemplateSelected([]);
                        setPrepareCampaign(false);
                        setTemplateWhatsapp(false);
                        reset({
                          campaignName: "",
                          // messageTypeId: "",
                          listId: "",
                          templateId: "",
                          isProgramCamp: "",
                          programDate: "",
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <footer>
              <div className="row">
                <div className="col-md-8"></div>

                <div className="col-md-4">
                  {/* Creación de la paginación simple */}
                  <Pagination
                    totalData={formatCampaign.length}
                    byPage={byPage}
                    setPage={setPage}
                  />
                </div>
              </div>
            </footer>
          </div>
        </>
      )}

      {mode === "create" && (
        <>
          <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-2" style={{height:"6%"}}>
            <div style={{ height: "45px", lineHeight: "95px" }}>
              <button
                type="button"
                className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex"
                onClick={() => {
                  setMode("list");
                  setInputs("none");
                  setTemplateSelected([]);
                  setPrepareCampaign(false);
                  reset({
                    campaignName: "",
                    // messageTypeId: "",
                    listId: "",
                    templateId: "",
                    isProgramCamp: "",
                    programDate: "",
                  });
                }}
              >
                <FaAngleLeft className="mr-1" /> Campañas
              </button>
            </div>

            <Header />
          </div>

          <section className="module-create-user" style={{ width: "90%", height:"90%" }}>
            <header className="message" style={{ height:"7%" }}>
              <div id="tittleFormProduct">
                <h1>Crear campaña</h1>
              </div>
            </header>

            <Navbar className="nav-create-campaign" style={{ height:"10%" }}>
              <li className="d-flex align-items-center">
                {formStep === 2 || formStep === 3 || prepareCampaign ? 
                  (<BsFillCheckCircleFill className="mr-2" style={{fontSize: "1.7rem", color: "#17c524"}} />
                ) : (
                  <span className={`number mr-2 ${formStep === 1 && "active"} ${formStep === 2 || formStep === 3 || prepareCampaign ? "check-ok" : null}`}>1</span>
                )}
                <span className={`title ${formStep === 1 && "activ"}`}>Campaña</span>
              </li>

              <li className="d-flex align-items-center">
                {formStep === 3 || prepareCampaign ? (
                  <BsFillCheckCircleFill className="mr-2" style={{fontSize: "1.7rem", color: "#17c524"}} />
                ) : (
                  <span className={`number mr-2 ${formStep === 2 && "active"} ${formStep === 3 || prepareCampaign ? "check-ok" : null}`}>2</span>
                )}
                <span className={`title ${formStep === 2 && "activ"}`}>Usuarios</span>
              </li>

              <li className="d-flex align-items-center">
                {prepareCampaign ? 
                  <BsFillCheckCircleFill className="mr-2" style={{fontSize: "1.7rem", color: "#17c524"}} /> 
                : 
                  <span className={`number mr-2 ${formStep === 3 && "active"} ${prepareCampaign ? "check-ok" : null}`}>3</span>
                }
                <span className={`title ${formStep === 3 && "activ"}`}>
                  Mensajes
                </span>
              </li>
            </Navbar>

            <form onSubmit={inputs === "edit" ? handleSubmit(editCampaign) : handleSubmit(postCampaign)} className="Form-create-new-campaign" style={{ height:"83%" }}>
              {/************************ PASO UNO ************************/}
              {formStep === 1 && (
                <div className="d-flex justify-content-center" style={{ height:"87%" }}>
                  <div className="field-name">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label htmlFor="name">Nombre de la campaña: </label>
                      <p>
                        Ingresa un nombre para ayudar a recordar de qué se trata
                        esta campaña
                      </p>
                      <div className="su-control-group">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          id="name"
                          readOnly={campaign.status}
                          {...register("campaignName", {
                            required: {
                              value: true,
                              message: "El nombre de la campaña es requerido.",
                            }
                          })}
                          onChange={(e) => {
                            setValue("campaignName", e.target.value);
                            trigger("campaignName");
                            setPrepareName(e.target.value);
                            const resultMap = campaigns.map((element) => element.nameCampaign.toLowerCase()).includes(e.target.value.toLowerCase());
                            if (resultMap) {setRepeatedName(true)} else {setRepeatedName(false)}
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") e.preventDefault();
                          }}
                        />
                      </div>
                      {repeatedName && (
                        <Alert>* Ya existe una campaña con este nombre</Alert>
                      )}
                      {errors.campaignName && errors.campaignName.message !== "" && (
                        <Alert>* {errors.campaignName.message}</Alert>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/************************ PASO DOS ************************/}
              {formStep === 2 && (
                <>
                  <div className="field-users" style={{ height:"87%" }}>
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0 pt-3">
                      <label htmlFor="name">Usuarios: </label>
                      <p>Selecciona la lista de usuarios a las que deseas comunicar tu mensaje o crea una lista nueva importándolas desde un archivo externo.</p>
                      <select
                        id="lists"
                        name="list"
                        className="form-control"
                        defaultValue=""
                        {...register("listId", {
                          required: {
                            value: true,
                            message: "La lista es requerida.",
                          },
                        })}
                        onChange={(e) => {
                          console.log("click para list users", e.target.value);
                          const nameList = formatList.filter(list => list._id == e.target.value)
                          console.log("-----nameList--------", nameList);

                          const createdDateList = new Date(nameList[0].created); 
                          const currentDateAdditionalFields =  new Date(aditionalFieldsDate.current); 


                          if(createdDateList < currentDateAdditionalFields){
                            Swal.fire({
                              title: "¡Atención!",
                              text: "La lista fue creada con anterioridad a la ultima modificacion de campos adicionales. Puede ocurrir que algunos contactos no hayan sido actualizados",
                              icon: "warning",
                            });
                          }
                          if(nameList.length > 0){
                            prepareList.current = nameList[0].name;
                          }
                        }}
                        disabled={campaign.status}
                      >
                        <option value="" key="0" disabled>
                          -- Selecciona una lista de usuarios --
                        </option>
                        {lists &&
                          formatList.map((list, k) => (
                            <option value={list._id} key={k}>
                              {list.name}
                            </option>
                          ))}
                      </select>
                      {errors.listId && (
                        <Alert>* {errors.listId.message}</Alert>
                      )}

                      <div className="row my-3">
                        <div className="col col-5">
                          <hr
                            role="separator"
                            aria-orientation="horizontal"
                            className="v-divider theme--light"
                          />
                        </div>
                        <div className="text-center d-flex align-center col col-2">
                          <div
                            className="row align-center justify-center"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "1 1 auto",
                            }}
                          >
                            {" "}
                            O{" "}
                          </div>
                        </div>
                        <div className="col col-5">
                          <hr
                            role="separator"
                            aria-orientation="horizontal"
                            className="v-divider theme--light"
                          />
                        </div>
                      </div>

                      <div className="text-center">
                        <a
                          href="javascript:;"
                          type="button"
                          name="Importar usuarios"
                          className="btn btn-lg btn-default mr-1"
                          onClick={() => {
                            setImportLists(true);
                            setNameList("");
                          }}
                        >
                          Importar usuarios
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="import-content"></div>
                </>
              )}
              {/************************ PASO TRES ***********************/}
              {formStep === 3 && (
                <>
                  <div className="field-template d-flex" style={{ height:"87%" }}>
                    <div id="content-add-info-template" className="su-control su-with-prefix su-with-suffix su-segmentation-disable px-5 h-100 my-0 py-3" style={{overflow:"auto"}}>
                      <label htmlFor="name">Plantillas: </label>
                      <p>Selecciona la plantilla que deseas para comunicarlo.</p>
                      <Select
                        styles={isDark == 'true' ? customStylesDark : customStyles}
                        className="form-control select-template"
                        placeholder="Selecciona una plantilla"
                        onChange={handleChange}
                        options={templates}
                        getOptionLabel={(option) => (
                          <div className="option d-flex" style={{fontSize: "15px"}}>
                            {`${option.name} - (${option.language})`}
                          </div>
                        )}
                        getOptionValue={(option) => `${option.name} - ${option.language}`}
                      />

                      {/* CARGAR ARCHIVOS  */}
                      {templateHeader && templateSelected.map((template, t) => {
                        return (
                        <div key={t}>
                          {contentHeader === "IMAGE" ? (
                            <div className="mt-3">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                <label>Cargar imagen: </label>
                                <div className="su-control-group">
                                  <input
                                    type="file"
                                    name="image"
                                    id="inputMedia"
                                    className="form-control"
                                    accept="image/gif,image/jpeg,image/jpg,image/png"
                                    // {...register("image", {
                                    //   required: {
                                    //     value: true,
                                    //     message: "El medio es requerido.",
                                    //   },
                                    // })}
                                    onChange={(e) => onFile(e)}
                                  />
                                  {/* {errors.image && (<Alert>* {errors.image.message}</Alert>)} */}
                                  {errorMedia && (<Alert>* El medio es requerido</Alert>)}
                                </div>
                              </div>
                            </div>
                          ) : contentHeader === "VIDEO" ? (
                            <div className="mt-3">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                <label>Cargar video: </label>
                                <div className="su-control-group">
                                  <input
                                    type="file"
                                    name="video"
                                    id="inputMedia"
                                    className="form-control"
                                    placeholder="Add profile picture"
                                    accept="video/*"
                                    // {...register("video", {
                                    //   required: {
                                    //     value: true,
                                    //     message: "El medio es requerido.",
                                    //   },
                                    // })}
                                    onChange={(e) => onFileVideo(e)}
                                  />
                                  {/* {errors.video && (<Alert>* {errors.video.message}</Alert>)} */}
                                  {errorMedia && (<Alert>* El medio es requerido</Alert>)}
                                  <div>
                                    <ul>
                                      <li style={{ padding: "5px" }}>
                                        <BsFillInfoCircleFill
                                          style={{
                                            fontSize: "17px",
                                            marginRight: "5px",
                                          }}
                                        />
                                        El tamaño maximo permitido es de 15MB.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : contentHeader === "DOCUMENT" ? (
                            <div className="mt-3">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                <label>Cargar documento: </label>
                                <div className="su-control-group">
                                  <input
                                    type="file"
                                    name="document"
                                    id="inputMedia"
                                    className="form-control"
                                    accept="audio/*,.pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    // {...register("document", {
                                    //   required: {
                                    //     value: true,
                                    //     message: "El medio es requerido.",
                                    //   },
                                    // })}
                                    onChange={(e) => onFileDocument(e)}
                                  />
                                  {/* {errors.document && (<Alert>* {errors.document.message}</Alert>)} */}
                                  {errorMedia && (<Alert>* El medio es requerido</Alert>)}
                                </div>
                              </div>
                            </div>
                          ) : contentHeader === "LOCATION" ? (
                            <div className="mt-3">
                            <div>
                              <label>Datos de la ubicación:</label>
                            </div>
                            <div className="d-flex">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-1 mr-2">
                                <div className="su-control-group">
                                  <input type="number" name="latitud" className="form-control" placeholder="Latitud" 
                                    {...register(`latitud`,{
                                      required:{
                                        value:true, 
                                        message: "La latitud es requerida."
                                      }
                                    })}
                                  />
                                </div>
                              </div>
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-1 mr-2">
                                <div className="su-control-group">
                                  <input type="number" name="longitud" className="form-control" placeholder="Longitud" 
                                    {...register(`longitud`,{
                                      required:{
                                        value:true, 
                                        message: "La longitud es requerida."
                                      }
                                    })}
                                  />
                                </div>
                              </div>
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-1 mr-2">
                                <div className="su-control-group">
                                  <input type="text" name="name" className="form-control" placeholder="Nombre" 
                                    {...register(`name`,{
                                      required:{
                                        value:true, 
                                        message: "El nombre es requerido."
                                      }
                                    })}
                                  />
                                </div>
                              </div>
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-1">
                                <div className="su-control-group">
                                  <input type="text" name="adress" className="form-control" placeholder="Dirección" 
                                    {...register(`adress`,{
                                      required:{
                                        value:true, 
                                        message: "La direccion es requerida."
                                      }
                                    })}
                                  />
                                </div>
                              </div>
                              </div>
                          </div>) : null}
                        </div>
                      )})}

                      {/* PINTAR CAMPOS PARA PARAMETROS DEL CONTENIDO*/}
                      {numberParams ? numberParams.map((param, k) => (
                        <div key={k} className="su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0">
                          <div className="su-control-group d-flex align-items-center">
                            <select
                              name={`param${k+1}`}
                              id={`param${k+1}`}
                              className="form-control mb-1 w-100"
                              defaultValue=""
                              {...register(`param${k+1}`, {
                                required: {
                                  value: true,
                                  message: "El parametro es requerido.",
                                },
                              })}
                              onChange={(e) => onParams(e, k)}
                            >
                              <option value="" key="99" disabled>
                                {`Introducir contenido para {{${k+1}}}:`}
                              </option>
                              {correspondence && correspondence.map((element, k) => (
                                <option value={element.field} key={k}>
                                  {element.fieldName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )) : null }

                    {/* PINTAR CAMPO PARA PARAMETRO DE URL DINAMICA */}
                    {listButtonsUrlDynamic && listButtonsUrlDynamic.map((btn, b) => (
                      <div key={b} className="su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0">
                        <div className="su-control-group">
                          <select
                            name={`urlParam`}
                            id={`urlParam`}
                            className="form-control mb-3 w-100"
                            {...register(`urlParam`, {
                              required: {
                                value: true,
                                message: "El parametro es requerido.",
                              },
                            })}
                          >
                            <option value="" key="0" disabled selected>{`Valor para parametro de la url: `}</option>
                            {correspondence &&
                              correspondence.map((element) => (
                                <option
                                  value={element.fieldCorresp}
                                  key={element.field}
                                >
                                  {element.fieldName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    ))}

                  </div>
                    
                  {/* MODULO PARA PREVISUALIZAR LA PLANTILLA SELECCIONADA */}
                  {templateSelected && templateSelected.map((template, t) => {
                    const contentFooter = template.content.filter(tem => {if (tem.type === "FOOTER") return tem})
                  return (
                    <div id="preview-template" className="h-100" key={t} style={{width:"40%", overflow:"auto"}}>
                      <div>
                        <div className="ml-0">
                          <section className="section-preview p-3">
                            <h3>
                              <span>Vista previa</span>
                            </h3>
                            <div className="section-div1 ml-2 mr-5">
                              <div className="section-div2">
                                <div className="section-div3">
                                  <div className="section-div4">
                                    <div className={contentHeader === "TEXT"
                                      ? "header-preview headerText"
                                      : selectedVideo
                                      ? "video-selected"
                                      : "header-preview"
                                    }>
                                      {contentHeader && contentHeader === "TEXT" && contentTextHeader}

                                      {contentHeader && contentHeader === "IMAGE" ? (
                                          <>
                                            {selectedImage ? (
                                              <img
                                                src={selectedImage}
                                                className="w-100"
                                                style={{
                                                  borderRadius: "5px",
                                                }}
                                              />
                                            ) : (
                                              <div className="medio-image custom-media"></div>
                                            )}
                                          </>
                                        ) : contentHeader === "VIDEO" ? (
                                          <>
                                            {selectedVideo ? (
                                              <ReactPlayer
                                                url={videoo}
                                                loop
                                                controls
                                                width="100%"
                                                height="100%"
                                              />
                                            ) : (
                                              <div className="medio-video custom-media"></div>
                                            )}
                                          </>
                                        ) : contentHeader === "DOCUMENT" ? (
                                          <>
                                            {documentt ? (
                                              <iframe
                                                src={documentt}
                                              ></iframe>
                                            ) : (
                                              <div className="medio-document custom-media"></div>
                                            )}
                                          </>
                                        ) : contentHeader === "LOCATION" ? (
                                          <>
                                            <div className="medio-location custom-media"></div>
                                          </>
                                        ) : null
                                      }
                                    </div>

                                    {contentHeader === "LOCATION" && 
                                      <div className="data-location">
                                        <div className="location-name">{"{{Location name}}"}</div>
                                        <div className="location-adress">{"{{Adress}}"}</div>
                                      </div>
                                    }

                                    <div className="section-div5">
                                      <span className="span">
                                        <span>
                                          {/* {content && content} */}
                                          {body && body}
                                        </span>
                                      </span>
                                    </div>

                                    {contentFooter.length > 0 ? (
                                      <div className="content-footer">
                                        {contentFooter[0].text}
                                      </div>
                                    ) : null}

                                    <time aria-hidden="true" className="_6xe5">
                                      {date}
                                    </time>
                                  </div>

                                  {/* LISTA DE BOTONES DE LLAMADA A LA ACCIÓN */}
                                  {listButtonsCallToAction && listButtonsCallToAction.map((btn, b) => (
                                    <div key={b} className="content-botton">
                                      <div>
                                        {btn.type == "PHONE_NUMBER" 
                                        ? <BsFillTelephoneFill className="mr-1" style={{ fontSize: "1.3rem"}}/>
                                        : <TiExport className="mr-1" style={{ fontSize: "1.3rem"}}/>}
                                        <span>
                                          {btn.text}
                                        </span>
                                      </div>
                                    </div>
                                  ))}


                                </div>

                                {/* LISTA DE BOTONES DE RESPUESTA RAPIDA */}
                                {listButtonsQuickReply && listButtonsQuickReply.map((btn, b) => (
                                  <div key={b} className="content-fastAnswer">
                                    <div>
                                      <span>{btn.text}</span>
                                    </div>
                                  </div>
                                ))}

                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  )})}
                </div>
                </>
              )}
              {/************************ PASO FINAL **********************/}
              {prepareCampaign ? (
                <>
                  <div className="description text-center d-flex align-items-center justify-content-center" style={{ height:"87%", flexDirection:"column" }}>
                    <h2 className="title title mt-1 mb-3 campaign-created">
                      {prepareName}
                    </h2>
                    <p
                      className="mb-0 mt-0 pt-2 pb-4 text-center"
                      style={{ fontSize: "16px" }}
                    >
                      <BsFillInfoCircleFill className="mr-2"/> 
                      Tu campaña está preparada y se encuentra en espera de ser enviada
                    </p>

                    <div className="list-result mb-4">
                      <div className="list-item">
                        <div className="list-item__action">
                          <BiSolidHelpCircle />
                        </div>
                        <div className="list-item__content">
                          <div className="list-item__title">EN ESPERA</div>
                          <div className="list-item__subtitle">
                            Estado de la campaña
                          </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="list-item__action">
                          <PiUsersThreeFill />
                        </div>
                        <div className="list-item__content">
                          <div className="list-item__title">
                            {`${numberPersons} personas`}{" "}
                          </div>
                          <div className="list-item__subtitle">
                            {nameListPrepare}
                          </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="list-item__action">
                          <FaComment />
                        </div>
                        <div className="list-item__content">
                          <div className="list-item__title">
                            1 Mensajes a enviar{" "}
                          </div>
                          <div className="list-item__subtitle">
                            Este valor puede cambiar{" "}
                          </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="list-item__action">
                          <FaCalendarCheck />
                        </div>
                        <div className="list-item__content">
                          <div className="list-item__title">{creationDate}</div>
                          <div className="list-item__subtitle">
                            Fecha de creación
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {modalProgram ? (
                    <div
                      className="px-4 mx-auto content-date-send"
                      style={{ width: "35%", padding: "25px 0 0 0" }}
                    >
                      <div className="date-send">
                        <div className="row pb-3">
                          <div className="w-100 text-center">
                            <h4 className="mb-0">Programar envío de campaña</h4>
                          </div>
                          <div className="col col-12">
                            <div className="content-program d-flex pt-3 mt-1 justify-content-center">
                              <div className="mr-3 d-inline-flex mb-1 mt-1">
                                <div className="content-icon">
                                  <FaCalendarDays />
                                </div>
                              </div>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                                <DateTimePicker
                                  disablePast={true}
                                  animateYearScrolling={true}
                                  emptyLabel="Fecha y hora de envío"
                                  cancelLabel="Cancelar"
                                  okLabel="Aceptar"
                                  value={startDate}
                                  onChange={setStartDate}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                            {errorDate ? (
                              <Alert className="ml-5 mt-0">
                                * Debes ingresar fecha y hora de envío
                              </Alert>
                            ) : null}
                          </div>

                          <div className="row justify-content-end bd col-12 mt-4 border-top-0">
                            <a
                              href="javascript:;"
                              className="btn btn-lg btn-default mr-1"
                              onClick={() => {
                                setModalProgram(false);
                                setStartDate(null);
                                setDateNull(false);
                              }}
                            >
                              Cancelar
                            </a>

                            <input
                              className="btn btn-lg btn-info"
                              value={
                                inputs === "edit"
                                  ? "Editar campaña"
                                  : "Programar campaña"
                              }
                              type="submit"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}

              <footer style={{height:"13%", padding:"10px"}}>
                <div className="row">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-6 right">
                    {/*********** ATRAS ***********/}
                    {formStep === 1 ? (
                      <a
                        href="javascript:;"
                        className="btn btn-lg btn-default mr-1"
                        onClick={() => {
                          setMode("list");
                          setInputs("none");
                          setPrepareCampaign(false);
                          reset({
                            campaignName: "",
                            // messageTypeId: "",
                            listId: "",
                            templateId: "",
                            isProgramCamp: "",
                            programDate: "",
                          });
                        }}
                      >
                        Volver
                      </a>
                    ) : !prepareCampaign ? (
                      <a
                        href="javascript:;"
                        className="btn btn-lg btn-default mr-1"
                        onClick={() => setFormStep(formStep - 1)}
                      >
                        Atras
                      </a>
                    ) : !modalProgram ? (
                      <a
                        href="javascript:;"
                        className="btn btn-lg btn-default mr-1"
                        onClick={() => {
                          setFormStep(3);
                          setPrepareCampaign(false);
                        }}
                      >
                        Atras
                      </a>
                    ) : null}

                    {/*********** CONTINUAR ***********/}
                    {formStep === 3 ? (
                      <input
                        type="button"
                        name="continue"
                        className="btn btn-lg btn-info"
                        value="Preparar campaña"
                        // disabled={!isValid}
                        onClick={() => {
                          if (templateSelected.length <= 0) { 
                            Swal.fire({
                              title: "¡Atención!",
                              text: "Debes seleccionar una plantilla para continuar",
                              icon: "error",
                            });
                          } else {
                            console.log('isValid isValid isValid isValid', isValid)
                            if (!isValid) {
                              console.log('00000000000000000000000000000000000')
                              Swal.fire({
                                title: "¡Atención!",
                                text: "Debes completar la información del contenido para poder continuar",
                                icon: "error",
                              });
                              
                            } else {
                              console.log('1111111111111111111111111111111111111111111111')
                              if (contentHeader && contentHeader != "TEXT") {
                                if (selectedImage || selectedDocument || selectedVideo) {
                                  setErrorMedia(false)
                                  onPrepareCampaign()
                                }else{
                                  setErrorMedia(true)
                                  document.getElementById('inputMedia').focus()
                                }
                              }else{
                                onPrepareCampaign()
                              }  
                            }
                          }                        
                        }}
                      />
                    ) : formStep === 2 ? (
                      <input
                        type="button"
                        name="continue"
                        className="btn btn-lg btn-info"
                        value="Continuar"
                        onClick={() => {
                          const {listId} = watch()
                          if (listId) {
                            setFormStep(formStep + 1);
                          } else {
                            Swal.fire({
                              title: "¡Atención!",
                              text: "Debes seleccionar o cargar una lista para continuar",
                              icon: "error",
                            });
                          }
                        }}
                      />
                    ) : !prepareCampaign ? (
                      <input
                        type="button"
                        name="continue"
                        className="btn btn-lg btn-info"
                        value="Continuar"
                        onClick={() => {
                          getIsDark()
                          if (repeatedName) {
                            Swal.fire({
                              title: "¡Atención!",
                              text: "Ya existe una campaña con este nombre",
                              icon: "error",
                            });
                          } else if(!prepareName){
                            Swal.fire({
                              title: "¡Atención!",
                              text: "Debes ingresar un nombre!",
                              icon: "error",
                            });                           
                          } else {
                            setFormStep(formStep + 1);
                            setSelectedImage("");
                          }
                        }}
                      />
                    ) : (
                      <>
                        {!modalProgram ? (
                          <>
                            <a
                              href="javascript:;"
                              className="btn btn-lg btn-default mr-1"
                              onClick={() => {
                                setModalProgram(true);
                              }}
                            >
                              Programar
                            </a>

                            <input
                              type="submit"
                              name="continue"
                              className="btn btn-lg btn-info"
                              value={
                                inputs === "edit"
                                  ? "Editar campaña "
                                  : "Enviar campaña "
                              }
                            />
                            {/* <FontAwesomeIcon className="ml-2" icon={faPaperPlane} /> */}
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </footer>
            </form>
          </section>
        </>
      )}

      <table
        className="table table-striped"
        id="tablePersonsCampaign"
        style={{ display: "none" }}
      >
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Código de area</th>
            <th>Teléfono</th>
            {FieldsNames &&
              FieldsNames.map((item, k) => <th key={k}>{item}</th>)}
          </tr>
        </thead>
      </table>

      <Modal
        // status={modalProgram}
        // changeStatus={setModalProgram}
        title="Programar campaña"
        // action={() => setModeImport('createName')}
        width="500px"
      >
        <Content>
          <div className="px-4 w-100">
            <form>
              <div className="row pb-3">
                <div className="col col-12">
                  <div className="content-program d-flex pt-3 mt-1">
                    <div className="mr-3 d-inline-flex mb-1 mt-1">
                      <div className="content-icon">
                        <faCalendarDays
                          className="ml-2"
                        />
                      </div>
                    </div>
                    <div className="content-inputs">
                      <div className="content-inputs-item">
                        <div className="input-date">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            locale="es"
                            timeFormat="HH:mm"
                            timeIntervals={30}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            withPortal
                            closeText="Cerrar"
                            {...register("isProgramCamp")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isProgramCamp ? (
                <div className="program-date">
                  <input
                    className="date-input"
                    type="date"
                    {...register("programDate", {
                      required: {
                        value: true,
                        message: "La fecha de programacion es requerida.",
                      },
                    })}
                  />
                  {errors.templateId && (
                    <Alert>* {errors.templateId.message}</Alert>
                  )}
                  {errors.programDate && (
                    <Alert>* {errors.programDate.message}</Alert>
                  )}
                </div>
              ) : null}
              <div className="row justify-content-evenly">
                <button
                  type="button"
                  name="cancelar"
                  className="btn bg-secondary col-1-sm-3 my-auto"
                  onClick={() => setModalProgram(false)}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  name="continue"
                  className="btn bg-success col-1-sm-3 my-auto"
                  onClick={() => {
                    console.log(
                      "guardando fecha",
                      ...register("isProgramCamp")
                    );
                  }}
                >
                  Programar campaña
                  <FaCalendarDays className="ml-2" />
                </button>
              </div>
            </form>
          </div>
        </Content>
      </Modal>

      <Modal
        status={modalContent}
        changeStatus={setModalContent}
        title="Plantilla - Mensaje a enviar"
        action={() => null}
        width="550px"
      >
        <Content>
          {content && <h1 className="title">¡Ya casi terminas!</h1>}
          <div className="content-template">
            {content ? (
              <p className="message">
                {content.content ? content.content : content}
              </p>
            ) : (
              <p className="no-message">
                No hay ninguna plantilla seleccionada.
              </p>
            )}
          </div>
          {/* <div className="row justify-content-around col-12 mt-4">
            <button onClick={() => {setModalContent(false);}} className="btn bg-success col-1-sm-3 my-auto">Cerrar</button>
          </div> */}

          <div className="row justify-content-end bd col-12 mt-4">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => {
                setModalContent(false);
              }}
            >
              Cerrar
            </a>
          </div>
        </Content>
      </Modal>

      <Modal
        status={importLists}
        changeStatus={setImportLists}
        title="Subir/Importar listas"
        action={() => setModeImport("createName")}
      >
        <Content>
          <form>
            {modeImport === "createName" ? (
              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <label>Nombre de la lista: </label>
                <div className="su-control-group">
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={nameList}
                    onChange={(e) => {setNameList(e.target.value); prepareList.current = e.target.value}}
                    onSubmit={(e) => e.preventDefault()}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="mb-3">
                  <h5>
                    Descargue{" "}
                    <span className="border-0 pointer bg-transparent" style={{color: "#5bc0de"}} onClick={downloadTable}>aquí</span>
                    {" "}la plantilla en formato Excel.
                  </h5>
                </div>
                <InputContent>
                  {excelFile ? (
                    <label htmlFor="file">
                      <BsFillCheckCircleFill style={{ color: "#5cb85c" }} className="mr-1"/>
                      Archivo cargado
                    </label>
                  ) : (
                    <label htmlFor="file">
                      <BsFileEarmarkExcelFill className="mr-1"/>
                      Selecciona un archivo
                    </label>
                  )}
                  <input
                    type="file"
                    id="file"
                    multiple
                    className="col-2-sm btn my-auto mb-1 "
                    onChange={uploadFile}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </InputContent>
              </>
            )}
          </form>

          <div className="row justify-content-end bd col-12 mt-4">
            {modeImport === "createName" ? (
              <>
                <a
                  href="javascript:;"
                  className="btn btn-lg btn-default mr-1"
                  onClick={() => {
                    setImportLists(false);
                    setModeImport("createName");
                  }}
                >
                  Cerrar
                </a>
                <input
                  className="btn btn-lg btn-info"
                  value="Aceptar"
                  type="submit"
                  onClick={() => setModeImport("charge")}
                />
              </>
            ) : (
              <>
                <a
                  href="javascript:;"
                  className="btn btn-lg btn-default mr-1"
                  onClick={() => setModeImport("createName")}
                >
                  Volver
                </a>
                <button
                  onClick={() => sendFile()}
                  className="btn btn-success btn-sm"
                >
                  Cargar archivo
                </button>
              </>
            )}
          </div>
        </Content>
      </Modal>

      {loadingPercentBar ? (
          <div id="overlay">
            <div id="progress-container">
                <ProgressBar width="230" trackWidth="13" percentage={percentBar} />
            </div>
          </div>
      ) : null}

    </Fragment>
  );
};

export default Campaign;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: #5bc0de;
    font-size: 24px;
    margin-bottom: 15px;
  }

  p {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .content-template {
    width: 100%;
    margin: 0 auto;
  }

  .message {
    // background: #e2e2e2;
    width: 90%;
    font-weight: 900;
    display: block;
    padding: 15px;
    margin: 0 auto;
  }

  input.btn-success,
  button.btn-danger {
    margin: 0 5px;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flex-container label {
    font-size: 20px;
    font-weight: 900;
  }

  .flex-container input {
    padding: 5px;
    font-size: 22px;
    border-radius: 12px;
    border: 1px solid gray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  h5 button {
    color: #5bc0de;
  }

  h5 button:hover {
    color: #28a1c5;
  }
`;

const Navbar = styled.ul`
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-size: 16px;
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  .number {
    background: #808080;
    // margin-right: 5px;
    padding: 2.5px 10px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    transition: all 0.3s ease;
  }

  .number.active {
    background: #5bc0de;
  }

  .title {
    font-weight: 600;
  }
  .title.activ {
    font-size: 18px;
    font-weight: 900;
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  margin-top: 10px;

  @media (max-width: 990px) {
    width: 100%;
    text-align: center;
  }
`;

const InputContent = styled.div`
  color: #6d7d88;
  background-color: #fdfefe;
  border: 1px solid #dae2e4;
  -moz-box-shadow: 2px 2px 0px 0px #dae2e4;
  -webkit-box-shadow: 2px 2px 0px 0px #dae2e4;
  box-shadow: 2px 2px 0px 0px #dae2e4;

  border-radius: 12px;
  transition: all 0.3s ease;

  input[type="file"] {
    display: none;
    color: white;
  }

  label {
    // color: white;
    height: 60px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    margin: auto;
    font-size: 20px;
    cursor: pointer;
  }

  &:hover {
    color: #6d7d88;
    background-color: #e0e7e8;
    border-color: #b7c6ca;
    -moz-box-shadow: 2px 2px 0px 0px #b7c6ca;
    -webkit-box-shadow: 2px 2px 0px 0px #b7c6ca;
    box-shadow: 2px 2px 0px 0px #b7c6ca;
  }
`;
