import React, { Fragment, useState, useEffect, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import Spinner from "../plugins/Spinner";
import useAPI from "../../hooks/useAPI";
import usePage from "../../hooks/usePage";
import Header from "../layout/Header";
import Swal from "sweetalert2";
import { Tooltip } from 'react-tooltip'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Avvvatars from "avvvatars-react";
import styled from "styled-components";
import Pagination from "../plugins/Pagination";
import Modal from "../layout/Modal";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Link } from "react-router-dom";

import { MdViewColumn } from "react-icons/md";
import { FaTable, FaSortDown, FaSortUp, FaEye } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { FaPlus, FaTags, FaSpinner, FaShoppingBasket } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";
import { BsTrash3, BsWhatsapp, BsPersonVcard, BsListStars, BsFillPersonCheckFill, BsPencilFill, BsPlusSquareDotted } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { CgNotes } from "react-icons/cg";
import { TiShoppingCart } from "react-icons/ti";

const Prospects = () => {
  //# Custoom Hooks
  const { urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();
  const { page, setPage, byPage, resetPage } = usePage();
  const {watch,register,handleSubmit,formState: { errors },reset,setValue} = useForm({
    mode: "all",
    defaultValues: {
      email: "",
    },
    note: "",
    segment: [],
    name: "",
    lastName: "",
    phone: "",
    email: "",
    answerName: "",
    answerContent: "",
    tags:[],
  });

  const userId = localStorage.getItem("USER_ID");
  const userLogout = localStorage.getItem("USER_NAME");

  //# Navegacion
  const navigate = useNavigate();

  //# Estados del Modulo (Modales, Spinner)
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("process");
  const [isDark, setIsDark] = useState(false);
  const [editStage, setEditStage] = useState(false);
  const [idStage, setIdStage] = useState(null);
  const [isColumnDragDisabled, setIsColumnDragDisabled] = useState(false);
  const [validation, setValidation] = useState(false);
  const [fieldsList, setFieldsList] = useState([]);
  const [allLists ,setAllLists] = useState([]);
  const [listOrders, setListOrders] = useState([]);
  const [ordersFilteredInfo, setOrdersFilteredInfo] = useState([]);

  //# Informacion del contacto
   //---------others--------- 
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [dataContactInfo, setDataContactInfo] = useState(null);
  const [modalViewNote, setModalViewNote] = useState(false);
  const [modeEdit, setModeEdit] = useState(false);
  const [idNote, setIdNote] = useState(false);
  const [addNewNote, setAddNewNote] = useState(false);
  const [addNewSegment, setAddNewSegment] = useState(false);
  const [loadingGetNote, setLoadingGetNote] = useState(false);
  const [isDisableSelect, setIsDisableSelect] = useState(true);
  const [contactStage, setContactStage] = useState(null);
  const [newNote, setNewNote] = useState("");
  const [noteSelected, setNoteSelected] = useState([])
  const [listsSelected, setListsSelected] = useState([]);
  //---------secciones--------- 
  const [notesSection, setNotesSection] = useState(false);
  const [tagsSection, setTagsSection] = useState(false);
  const [dataSection, setDataSection] = useState(false); 
  const [listsSection, setListsSection] = useState(false); 
  const [responsibleSection, setResponsibleSection] = useState(false); 
  const [ordersSection, setOrdersSection] = useState(false);

  //# Estados de la data
  const [listStages, setListStages] = useState([]);

  //# Estados de variables del modulo
  const [nameStage, setNameStage] = useState("");

  const listsByCompany = useRef([])

  const formPutPerson = useForm();
  const error8 = formPutPerson.formState.errors;

  const generateId = (longitud) => {
    let resultado = '';
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const caracteresLongitud = caracteres.length;
    for (let i = 0; i < longitud; i++) {
        resultado += caracteres.charAt(Math.floor(Math.random() * caracteresLongitud));
    }
    return resultado;
  }

  // CRUD
  // --> LISTAR ETPAS
  const getStages = async (requiredLoading = true) => {
    try {
      setLoading(requiredLoading);
      const url = urlAPI_2 + `stages/?assigns=${userId}`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(result.length <= 0) {
        setListStages([]);
        setValidation(true);
      } else {
        setListStages(result);
        setValidation(false);
      }
      setLoading(false);
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };
  //#--> Crear
  const postStages = async () => {
    try {
      setLoading(true);

      const dataStage = {
        name: nameStage,
        prospects: [],
        needed: false,
      }

      const url = urlAPI_2 + "stages";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(dataStage)};
      const response = await fetchWithAuth(url, options);
      await getStages();
      
      setNameStage("");
      setLoading(false);
      Swal.fire({
        title: "¡Bien!",
        text: "Etapa creada con éxito",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log(error);
    }
  };
  //#--> Actualizar
  const putStages = async (id) => {

    const dataStage = {
      name: nameStage,
      prospects: [],
      needed: false,
    }

    try {
      setLoading(true);
      const url = urlAPI_2 + `stages/${id}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(dataStage)};
      const response = await fetchWithAuth(url, options);
      await getStages();
      setNameStage("");
      setEditStage(false);
      setIdStage(null);
      Swal.fire({
        title: "¡Bien!",
        text: "Etapa Actualizada",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log(error);
    }
  };
  //#--> Eliminar
  const deleteStages = async (id) => {
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar esta etapa?",
        text: "No podrás recuperarla.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const url = urlAPI_2 + `stages/${id}`;
          const options = { method: "DELETE", mode: "cors"};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();

          //#--> Actualización de la tabla
          await getStages()
          setLoading(false);

          //#--> Confirmación de la eliminación
          Swal.fire({
            title: "¡Bien!",
            text: "Etapa eliminada",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  //#--> Cambiar Prospecto
  const putProspect = async (data, draggableId) => {
    
    const findUser = data.prospects.find(prospect => prospect == draggableId)
    if(!findUser) {
      try {
        const prospects = [
          ...data.prospects.map(prospect => {
            if(typeof prospect === 'object') {
              return prospect._id
            }
          }), 
          draggableId
        ]
        const dataStage = {
          prospect: draggableId,
          data: {
            ...data,
            prospects: prospects
          }
        }

        const url = urlAPI_2 + `stagesAddProspect/${data._id}`;
        const options = { method: "PUT", mode: "cors", body: JSON.stringify(dataStage)};
        await fetchWithAuth(url, options);
        const requiredLoading = false
  
        await getStages(requiredLoading);
      } catch (error) {
        console.log(error);
      }
    }
    
  };

  //#--> Obtener Campos adicionales
  const getFields = async () => {
    try {
      const url = urlAPI_2 + "aditionalFields";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      const res = result.data;
      setFieldsList(res);
    } catch (error) {
      console.log(error);
    }
  };
  //# Obtner todas las listas
  const getAllLists = async () => {
    try {
      const url = `${urlAPI_1}lists`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();

      if (result.type === "success") {
        listsByCompany.current = result.data;
      } else {
        listsByCompany.current = [];
      }
    } catch (error) {
      console.error("Error al obtener todas las listas:", error);
      listsByCompany.current = [];
    }
  };
  //#--> Obtener Listas Por Id
  const getLists = async (userId) => {
    setAllLists([]);
    try {
      const list_return = listsByCompany.current
      .filter(element => element.persons)
      .map(element => {
        const personslistSplit = element.persons.split(',');
        const count = personslistSplit.length;
        const inList = personslistSplit.includes(userId);
        
        return {
          _id: element._id,
          company: element.company,
          description: element.description,
          name: element.name,
          created: element.created,
          count,
          inList,
        };
      });
      setAllLists(list_return);
    }catch (error) {
      console.log(error);
    }
  };
  //#--> Optener Orders
  const getOrders = async () => {
    try {
      const url = urlAPI_2 + 'getOrders';
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(!result.data) {
        setListOrders([]);
      } else {
        setListOrders(result.data);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };

  const addStage = () => {
    const id = generateId(10)
    const newStage = {
      _id: id,
      name: "",
      count: 0,
      prospects: [],
      needed: false,
      new: true,
    }

    let stages = [...listStages];
    stages.push(newStage)

    setListStages(stages);
  }
  const cancelNewStage = (id) => {
    const filterStages = listStages.filter(v => v._id !== id)
    setListStages(filterStages);
    setNameStage(null);
  }

  const getIsDark = () => {
    const dark = localStorage.getItem("THEME");
    
    setIsDark(dark);
  }
  
  const onDragStart = (start) => {
    const { source } = start;
    if (source.droppableId !== 'droppable-columns') {
      setIsColumnDragDisabled(true);
    }
  };
  const onDragEnd = async (result) => {
    setIsColumnDragDisabled(false);
  
    if (!result.destination) return;
    
    const { source, destination, draggableId } = result;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const sourceDroppableId = source.droppableId;
    const destinationDroppableId = destination.droppableId;
  
    // Validar que las columnas solo se muevan dentro de la sección de columnas
    if (sourceDroppableId === 'droppable-columns' && destinationDroppableId !== 'droppable-columns') {
      console.error('No se puede mover una columna a una sección de ítems');
      return;
    }
  
    // Validar que los ítems solo se muevan dentro de sus respectivas columnas
    if (sourceDroppableId !== 'droppable-columns' && destinationDroppableId === 'droppable-columns') {
      console.error('No se puede mover un ítem a una sección de columnas');
      return;
    }
  
    const newListStages = Array.from(listStages);
  
    // Reordenar columnas
    if (sourceDroppableId === 'droppable-columns' && destinationDroppableId === 'droppable-columns') {
      const [removed] = newListStages.splice(sourceIndex, 1);
      newListStages.splice(destinationIndex, 0, removed);
      setListStages(newListStages);
      return;
    }
  
    // Mover ítems entre columnas
    const sourceStage = newListStages.find(stage => stage._id === sourceDroppableId);
    const destStage = newListStages.find(stage => stage._id === destinationDroppableId);
  
    if (!sourceStage || !destStage) {
      console.error('Etapa no encontrada');
      return;
    }
  
    if (sourceDroppableId !== destinationDroppableId) {
      const sourceProspects = Array.from(sourceStage.prospects);
      const destProspects = Array.from(destStage.prospects);
      const [removed] = sourceProspects.splice(sourceIndex, 1);
      destProspects.splice(destinationIndex, 0, removed);
  
      sourceStage.prospects = sourceProspects;
      destStage.prospects = destProspects;
    } else {
      // Reordenar ítems dentro de la misma columna
      const stage = newListStages.find(stage => stage._id === sourceDroppableId);
      const copiedProspects = Array.from(stage.prospects);
      const [removed] = copiedProspects.splice(sourceIndex, 1);
      copiedProspects.splice(destinationIndex, 0, removed);
  
      stage.prospects = copiedProspects;
    }
    
    setListStages(newListStages);
    await putProspect(destStage, draggableId)
  };

  const handleContactInfo = (item, stage) => {
    setShowContactInfo(true);
    setContactStage(stage);
    let lists = []
    if(item.lists && Object.keys(item.lists).length > 0) {
      lists = Object.keys(item.lists).map(key => item.lists[key]);
    }
    const info = {
      ...item,
      lists: lists
    }
    setDataContactInfo(info);
    getLists(info._id);
    ordersByUser(info);
    formPutPerson.reset(info, { keepDefaultValues: true });
  }
  const reloadDataContact = async (stageId) => {
    const url = urlAPI_2 + `stages/?assigns=${userId}`;
    const options = { method: "GET", mode: "cors"};
    const response = await fetchWithAuth(url, options);
    const stages = await response.json();

    const findStage = stages.find(v => v._id == stageId)
    if(findStage) {
      if(findStage.prospects.length) {
        const findProspect = findStage.prospects.find(v => v._id == dataContactInfo._id)
        if(findProspect) {
          let lists = []
          if(findProspect.lists && Object.keys(findProspect.lists).length > 0) {
            lists = Object.keys(findProspect.lists).map(key => findProspect.lists[key]);
          }
          const info = {
            ...findProspect,
            lists: lists
          }
          setDataContactInfo(info);
          getLists(info._id);
          formPutPerson.reset(info, { keepDefaultValues: true });
        }
      }
    }
  }

  //#--> Funciones para Informacion del contacto
  //--------------------Notas-------------------
  //Funcion que obtiene una nota
  const getNote = async (id) => {
    setLoadingGetNote(true)
    setModeEdit(true);
    setAddNewNote(true);
    setIdNote(id);
    try {
      const url = urlAPI_1 + "notes/" + dataContactInfo.phone + "/" + id;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();

      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (result.data.length === 0) {
        reset({ note: "" });
      } else {
        reset({ note: result.data[0].note });
      }
      setLoadingGetNote(false)

    } catch (error) {
      console.log(error);
    }
  };
  //Ver infromación de la nota seleccionada
  const viewNote = async (data) => {
    setNoteSelected(data)
    setModalViewNote(true)
  }
  //Enviar nueva nota
  const postNote = async () => {
    const { note } = watch();
    var date = new Date();

    var object = {
      note: note,
      date: date,
      agent: userLogout,
    };
    try {
      setLoading(true);
      const url = urlAPI_1 + "addNote/" + dataContactInfo.phone;
      const options = { method: "POST", mode: "cors", body: JSON.stringify(object)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();

      reset({ note: "" });
      setNewNote("");
      setAddNewNote(false);
      await reloadDataContact(contactStage);
      setLoading(false);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log(error);
    }
  };
  //Función para editar una nota
  const putNote = async () => {
    const { note } = watch();
    var date = new Date();

    var newObject = {
      note: note,
      date: date,
      agent: userLogout,
      id: idNote,
    };
    try {
      setLoading(true);
      const url = urlAPI_1 + `addNote/${dataContactInfo.phone}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(newObject)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();

      reset({ note: "" });
      setAddNewNote(false);
      await reloadDataContact(contactStage);
      setLoading(false);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log(error);
    }
  };
  //Función para eliminar una nota
  const deleteNote = async (id) => {
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar esta nota?",
        text: "No podrás recuperarla.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const url = urlAPI_1 + `notes/${dataContactInfo.phone}/${id}`;
          const options = { method: "DELETE", mode: "cors"};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();

          await reloadDataContact(contactStage);
          setLoading(false);
          Swal.fire({
            title: "¡Bien!",
            text: result.message,
            icon: result.type,
            confirmButtonText: "Aceptar",
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  //--------------Persona---------------
  //Funcion para actualizar
  const putPerson = async () => {
    const objectData = formPutPerson.watch();
    const id = objectData._id;
    let tags = []
    for(let tag of dataContactInfo.tags){
      if (tag.tagActive) {
        tags.push(tag._id)
      }
    }
    objectData.tags = tags.join(',');
    objectData.responsible = userLogout;
    try {
      setLoading(true);
      const url = urlAPI_1 + `persons/${id}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(objectData)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      await reloadDataContact(contactStage);
      setLoading(false);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      setLoading(false);
    }
  };
  //-----------Listas-------------
  //#--> Afrear usuario a listas
  const addUserToList = async () => {
    setAddNewSegment(false);
    const values = {
      userId: dataContactInfo._id,
      dataSegmentCheck: listsSelected,
    };
    try {
      setLoading(true);
      const url = urlAPI_1 + "addUserToList";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(values)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      await reloadDataContact(contactStage);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
      });
      setIsDisableSelect(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  //Funcion para almacenar las listas asignadas al contacto a crear
  const handleChangeList = data => {
    let idLists = [];
    for(let list of data){idLists.push(list._id)}
    setListsSelected(idLists);
    setDataContactInfo((prev) => ({
      ...prev,
      lists: data
    }))
  };
  
  const ordersByUser = (info) => {
    if (listOrders.length > 0) {
      let phone = info.callingCode + info.phone
      if (phone.startsWith("+")) {
        phone = phone.replace("+", "");
      }

      const ordersFiltered = listOrders.filter(({phoneNumber}) => {
        return phoneNumber == phone; 
      });
      setOrdersFilteredInfo(ordersFiltered);
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
  };
  const customStylesDark = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };
  
  useEffect(() => {
    getIsDark()

    const fetchData = async () => {
      try {
        await Promise.all([
          getStages(),
          getFields(),
          getAllLists(),
          getOrders()
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="container-fluid p-0 h-100">
        <div className="col-xs-9 heading mt-1">
          <div className="title-prospects">
            <h1>Centro de clientes potenciales</h1>
          </div>
          <Header />
        </div>

        <div className="row p-3" style={{height: "92%"}}>
          <div className="h-100" style={{width: showContactInfo ? "75%" : "100%"}}>
            <div className="header-prospects py-2">
              <div className="col-xs-9 heading ml-3 mt-1" style={{marginBottom: "5px"}}>
                <div className="row align-items-center">
                  <button 
                    className="border-0 pointer" 
                    style={{
                      backgroundColor: mode == "process" ? "rgba(63, 181, 216, 0.2)" : "transparent",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      height: "30px",
                      color: mode == "process" ? "#3FB5D8" : "#9FB4B9",
                    }}
                    onClick={() => {
                      setMode("process")
                    }}
                  >
                    <div className="d-flex aling-items-center">
                      <MdViewColumn 
                        style={{ 
                          fontSize: "20px",
                        }}
                      />
                      <span className="ml-2 f-14">
                        Vista de proceso
                      </span>
                    </div>
                  </button>
                  <button 
                    className="border-0 pointer ml-2" 
                    style={{
                      backgroundColor: mode == "table" ? "rgba(63, 181, 216, 0.2)" : "transparent",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      height: "30px",
                      color: mode == "table" ? "#3FB5D8" : "#9FB4B9",
                    }}
                    onClick={() => {
                      setMode("table")
                    }}
                  >
                    <div className="d-flex aling-items-center">
                      <FaTable 
                        style={{ 
                          fontSize: "20px",
                        }}
                      />
                      <span className="ml-2 f-14">
                        Vista de tabla
                      </span>
                    </div>
                  </button>
                </div>
                {/* Oculto Temporalmente */}
                {/* <div className="pr-3">
                  <button 
                    className="border-0 pointer" 
                    style={{
                      backgroundColor: "rgba(63, 181, 216, 0.2)",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      height: "30px",
                      color: "#3FB5D8",
                    }}
                    onClick={() => {}}
                  >
                    <div className="d-flex aling-items-center">
                      <FaPlus 
                        style={{ 
                          fontSize: "20px",
                        }}
                      />
                      <span className="ml-2 f-14">
                        Crear cliente potencial
                      </span>
                    </div>
                  </button>
                </div> */}
              </div>
            </div>
            {mode == "process" ? 
              (
                <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable-columns" direction="horizontal" isDropDisabled={isColumnDragDisabled}>
                    {(provided) => (
                      <div 
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="pt-4 px-0" 
                        style={{ height: "90%"}}
                      >
                        <div className="container-prospects" style={{ height: "100%", display: "flex", gap: "10px", overflowX: "auto", overflowY: "hidden" }}>
                          {listStages.length > 0 && listStages.map((stage, index) => (
                            <Draggable key={stage._id} draggableId={String(stage._id)} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <Droppable key={stage._id} droppableId={stage._id.toString()} direction="vertical">
                                    {(provided) => (
                                      <div 
                                        ref={provided.innerRef}
                                        {...provided.droppableProps} 
                                        key={stage._id} 
                                        className="card-stages"
                                      >
                                        {stage.new ? (
                                          <>
                                            <div className="col p-0">
                                              <label htmlFor="name" style={{fontWeight: 200}}>Título: </label>
                                              <div className="su-control-group">
                                                <input
                                                  className="form-control"
                                                  id="name"
                                                  name="name"
                                                  type="text"
                                                  style={{width: "100%", height: "35px"}}
                                                  value={nameStage|| ""}
                                                  onChange={(e) => {
                                                    setNameStage(() => setNameStage(e.target.value))
                                                  }}
                                                />
                  
                                                {!nameStage ? (
                                                  <p style={{fontSize: 11, fontWeight: 200}}>
                                                    Campo Nombre es obligatorio.
                                                  </p>
                                                ): null}
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-center mt-2">
                                              <div>
                                                <input
                                                  type="button"
                                                  className="btn btn-default"
                                                  value="Cancelar"
                                                  onClick={() => {cancelNewStage(stage._id)}}
                                                />
                                              </div>
                                              <div>
                                                <input
                                                  type="button"
                                                  className="btn btn-info ml-2"
                                                  value="Guardar"
                                                  onClick={() => {postStages()}}
                                                  disabled={!nameStage}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        ): (
                                          <>
                                            <div className="d-flex justify-content-between font-weight-bold" style={{fontSize: "16px"}}>
                                              {editStage && idStage == stage._id ? (
                                                <div>
                                                  <div className="col p-0">
                                                    <label htmlFor="name" style={{fontWeight: 200}}>Título: </label>
                                                    <div className="su-control-group">
                                                      <input
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        style={{width: "100%", height: "35px"}}
                                                        value={nameStage|| ""}
                                                        onChange={(e) => {
                                                          setNameStage(() => setNameStage(e.target.value))
                                                        }}
                                                      />
                  
                                                      {!nameStage ? (
                                                        <p style={{fontSize: 11, fontWeight: 200}}>
                                                          Campo Nombre es obligatorio.
                                                        </p>
                                                      ): null}
                                                    </div>
                                                  </div>
                                                  <div className="d-flex justify-content-center mt-2">
                                                    <div>
                                                      <input
                                                        type="button"
                                                        className="btn btn-default"
                                                        value="Cancelar"
                                                        onClick={() => {
                                                          setEditStage(false)
                                                          setNameStage(null)
                                                          setIdStage(null)
                                                        }}
                                                      />
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="button"
                                                        className="btn btn-default ml-2"
                                                        value="Guardar"
                                                        onClick={() => {putStages(stage._id)}}
                                                        disabled={!nameStage}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div>
                                                  {!stage.needed ? (
                                                    <>
                                                      <span
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content="Haz clic para editar el nombre de la etapa"
                                                        onClick={() => {
                                                          const find = listStages.find(v => v.new && v.new == true)
                                                          if(!find && !editStage) {
                                                            setEditStage(true)
                                                            setNameStage(stage.name)
                                                            setIdStage(stage._id)
                                                          }
                                                        }}
                                                      >
                                                        {stage.name}
                                                      </span>
                                                      <Tooltip id="my-tooltip" className="tooltip-prospects"/>
                                                    </>
                                                  ) : (
                                                    <span>
                                                      {stage.name}
                                                    </span>
                                                  )}
                                                </div>
                                              )}
                                              <div className="d-flex align-items-center">
                                                {idStage !== stage._id && (
                                                  <div>
                                                    {stage.count}
                                                  </div>
                                                )}
                                                {!stage.needed && !editStage && (
                                                  <div className="row justify-content-around ml-1">
                                                    <div className="dropdown" style={{height: "24px"}}>
                                                      <button
                                                        className="bg-transparent border-0"
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        style={{height: "100%"}}
                                                      >
                                                        <IoMdMore className="text-prospects f-24 pointer" />
                                                      </button>
                                                      <div
                                                        className="dropdown-menu p-2"
                                                        aria-labelledby="dropdownMenuButton"
                                                      > 
                                                        <button
                                                          onClick={() => {deleteStages(stage._id)}}
                                                          className="text-danger border-0 bg-transparent d-block pointer mt-2"
                                                        >
                                                          <BsTrash3 style={{ fontSize: "18px" }}/>
                                                          <span className="ml-2 text-prospects f-14">
                                                            Eliminar etapa
                                                          </span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            
                                            {idStage !== stage._id && (
                                              <div className="py-4 my-1 px-1 container-list-prospects" style={{height: "95%", overflowY: "auto", overflowX: "hidden"}}>
                                                {stage.prospects.map((item, index) => (
                                                  <Draggable key={item._id} draggableId={item._id.toString()} index={index}>
                                                    {(provided) => (
                                                      <div 
                                                        className="list-prospects" 
                                                        style={{justifyContent: "flex-start"}}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        onClick={() => {
                                                          handleContactInfo(item, stage._id);
                                                        }}
                                                      >
                                                        <div className="d-flex align-items-center">
                                                          <div className="col-2 pl-1" style={{ alignItems: "center", textAlign: "end" }}>
                                                            <IconList className="cont-avatar">
                                                              <Avvvatars value={item.name} />
                                                            </IconList>
                                                          </div>
                                                          <div className="px-2 ml-3 col-8">
                                                            <div>
                                                              <strong className="name-contact">
                                                                {(item.name && item.name.length > 16)
                                                                  ? item.name.substr(0, 16) + "..."
                                                                  : item.name}
                                                              </strong>
                                                            </div>
                                                            <div className="span-color">
                                                              <span style={{fontSize: "0.75rem"}}>
                                                                {(item.callingCode && item.callingCode.includes('+') ? "" : "+") + item.callingCode + ' ' + item.phone}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="ml-2">
                                                          <ul className="d-flex">
                                                            {item.tags && item.tags.slice(0, 3).map((tag, t) => (
                                                              <li
                                                                key={t}
                                                                className="px-2 py-0 mr-2 border-0"
                                                                style={{
                                                                  backgroundColor: tag.color,
                                                                  fontSize: "0.75rem",
                                                                  color: "#fff",
                                                                  borderRadius: "7px"
                                                                }}
                                                              >
                                                                {tag.title.length >= 6
                                                                  ? tag.title.substr(0, 6) + "..."
                                                                  : tag.title
                                                                }
                                                              </li>
                                                            ))}
                                                            {item.tags && item.tags.length > 3 && (
                                                              <li
                                                                className="px-0 py-0 mr-2 border-0"
                                                                style={{
                                                                  fontSize: "0.8rem",
                                                                }}
                                                              >
                                                                +1
                                                              </li>
                                                            )}
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                ))}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </Droppable>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          <div className="card-stages">
                            <div>
                              <button 
                                className="border-0 pointer" 
                                style={{
                                  backgroundColor: "transparent",
                                  fontWeight: "bold",
                                  borderRadius: "4px",
                                  height: "30px",
                                  color: "#3FB5D8",
                                }}
                                onClick={() => {
                                  const find = listStages.find(v => v.new && v.new == true)
                                  if(!find && !editStage) addStage()
                                }}
                              >
                                <div className="d-flex aling-items-center">
                                  <FaCirclePlus 
                                    style={{ 
                                      fontSize: "20px",
                                    }}
                                  />
                                  <span className="ml-2 f-14">
                                    Agregar etapa
                                  </span>
                                </div>
                              </button>
                            </div>
                          </div>
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )
            : 
              (
              <>
                <div className="container-fluid mt-3 px-0" style={{ height:"75%", overflowY:"auto", overflowX:"none" }}>
                  <table className="table table-striped table-campaigns">
                    <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
                      <tr>
                        <th>Acciones</th>
                        <th>Etiquetas</th>
                        <th>Nombre</th>
                        <th>Teléfono</th>
                        <th>Etapa</th>
                      </tr>
                    </thead>
                    <tbody>
                    {listStages.length > 0 && listStages.map((stage) => (
                      stage.prospects.map((prospect) => (
                        <tr 
                          key={prospect._id} 
                          onClick={() => {
                            setShowContactInfo(true);
                            setDataContactInfo(prospect);
                          }}
                        >
                          <td>
                            <div className="row justify-content-around">
                              <BsWhatsapp
                                className="mr-1" 
                                style={{ fontSize: "23px", color: prospect.wpp_enabled ? "#00a884" : "gray" }} 
                                title="Enviar mensaje"
                                onClick={prospect.wpp_enabled ? () => {
                                  navigate(`/home/chat`, { 
                                    state: { 
                                      parametro: prospect.callingCode + prospect.phone, 
                                      idContact: prospect._id,
                                      isChat: true
                                    } 
                                  })
                                } : undefined}
                                disabled={!prospect.wpp_enabled}
                              />
                            </div>
                          </td>
                          <td style={{width: "20%"}}>
                            <ul className="d-flex flex-wrap" style={{lineHeight: "1.8"}}>
                              {prospect.tags && prospect.tags.slice(0, 3).map((tag, t) => (
                                <li
                                  key={t}
                                  className="px-2 py-0 mr-2 border-0"
                                  style={{
                                    backgroundColor: tag.color,
                                    fontSize: "0.75rem",
                                    color: "#fff",
                                    borderRadius: "7px",
                                  }}
                                >
                                  {tag.title.length >= 6
                                    ? tag.title.substr(0, 6) + "..."
                                    : tag.title
                                  }
                                </li>
                              ))}
                              {prospect.tags && prospect.tags.length > 3 && (
                                <li
                                  className="px-0 py-0 mr-2 border-0"
                                  style={{
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  +1
                                </li>
                              )}
                            </ul>
                          </td>
                          <td>
                            {prospect.name}
                          </td>
                          <td>
                            {prospect.callingCode.includes('+') ? "" : "+"} {prospect.callingCode + ' ' + prospect.phone}
                          </td>
                          <td>
                            {stage.name}
                          </td>
                        </tr>
                      ))
                    ))}
                    </tbody>
                  </table>
                  {validation && (
                    <div className="text-center mt-3">
                      <div className="empty">
                        <h1>No tienes prospectos</h1>
                      </div>
                    </div>
                  )}
                </div>

                <footer>
                  <div className="row">
                    <div className="col-md-8"></div>

                    <div className="col-md-4">
                      <Pagination
                        totalData={listStages.length}
                        byPage={byPage}
                        setPage={setPage}
                      />
                    </div>
                  </div>
                </footer>
              </>
              )
            }
          </div>

          {showContactInfo && (
            <div className="prospect-info container-list-prospects">
              <section className='module-chat info active'>
                <header className="message" style={{height:"7%"}}>
                  <div className="d-flex">
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <Botton
                        type="button"
                        onClick={() => {
                          setShowContactInfo(false)
                          setDataContactInfo(null);
                        }}
                      >
                        <IoClose className="icon-cancel" style={{ fontSize: "1.5rem" }} />
                      </Botton>
                    </div>
                    <div id="customer-name" className="ml-2 d-flex">
                      <h1>Info. del Prospecto</h1>
                    </div>
                  </div>
                </header>

                <div className="user-information mp-2" style={{ height: "93%", overflow: "auto" }}>
                  <ul className="chat">
                    <li>
                      <Icon>
                        <Avvvatars value={dataContactInfo.name} />
                      </Icon>
                    </li>
                    <li className="text-center">
                      <h5>{dataContactInfo.name}</h5>
                    </li>
                    <li className="text-center">
                      <p className="d-flex align-items-center justify-content-center span-color">
                        <BsWhatsapp
                          className="mr-1" 
                          style={{ fontSize: "23px", color: dataContactInfo.wpp_enabled ? "#00a884" : "gray" }} 
                          title="Enviar mensaje"
                          onClick={dataContactInfo.wpp_enabled ? () => {
                            navigate(`/home/chat`, { 
                              state: { 
                                parametro: dataContactInfo.callingCode + dataContactInfo.phone, 
                                idContact: dataContactInfo._id,
                                isChat: true
                              } 
                            })
                          } : undefined}
                          disabled={!dataContactInfo.wpp_enabled}
                        />
                        {dataContactInfo.callingCode.includes('+') ? "" : "+"}{dataContactInfo.callingCode + ' ' + dataContactInfo.phone}
                      </p>
                    </li>
                  </ul>
                  <div>
                    {/* Informacion Personal */}
                    <div className="main-data">
                      <h5 className="pointer d-flex justify-content-between"
                        onClick={() => {
                          setTagsSection(false);
                          setNotesSection(false);
                          setListsSection(false);
                          setResponsibleSection(false);
                          setOrdersSection(false);
                          setDataSection(prev => !prev);
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <BsPersonVcard className="mr-1"/>
                          Datos principales
                        </div>
                        {dataSection ?
                          <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                          <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                        }
                      </h5>
                      {dataSection && (
                        <form onSubmit={formPutPerson.handleSubmit(putPerson)}>
                          <Div className="justify-content-center pt-2">
                            <div className="form-group mr-0 col-6 span-color">
                              <label htmlFor="email">Correo:</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                {...formPutPerson.register("email", {
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Formato incorrecto",
                                  },
                                })}
                              />
                              {error8.email && <Alert className="w-100">* {error8.email.message}</Alert>}
                            </div>
                            <div className="form-group mr-0 col-6 span-color">
                              <label htmlFor="name">Nombre:</label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                {...formPutPerson.register("name", {
                                  required: {
                                    value: true,
                                    message: "El nombre es requerido.",
                                  },
                                })}
                              />
                              {error8.name && <Alert>* {error8.name.message}</Alert>}
                            </div>
                            
                            {fieldsList && fieldsList.length && fieldsList.map((field, k) => (
                              <div key={k} className="form-group mr-0 col-6 span-color">
                                <label htmlFor={field.name}>{field.name}</label>
                                {field.type === "textarea" ? (
                                  <textarea
                                    name={field.name}
                                    className="form-control"
                                    id={field.name}
                                    {...formPutPerson.register(field.name)}
                                  ></textarea>
                                ) : (
                                  <input
                                    type={field.type}
                                    className="form-control"
                                    name={field.name}
                                    id={field.name}
                                    {...formPutPerson.register(field.name)}
                                  />
                                )}
                              </div>
                            ))}
                          </Div>
                          <div className="text-right pr-2">
                            <input
                              className="btn btn-lg btn-info"
                              style={{ fontSize: "0.89em" }}
                              value="Actualizar"
                              name="submit"
                              id="submit"
                              type="submit"
                            />
                          </div>
                        </form>
                      )}
                    </div>

                    {/* Notas */}
                    <div className="condition">
                      <h5
                        className="pointer d-flex justify-content-between"
                        onClick={() => {
                          setTagsSection(false);
                          setDataSection(false);
                          setListsSection(false);
                          setResponsibleSection(false);
                          setOrdersSection(false);
                          setNotesSection(prev => !prev);
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <CgNotes className="mr-1"/>
                          Notas
                        </div>
                        {notesSection ?
                          <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                          <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                        }
                      </h5>
                      {notesSection && (
                        <>
                          <div className="mt-3" style={{borderBottom: "2px solid rgb(229 231 235 / 51%)",}}>
                            <ul className="list-notes" style={{ marginLeft: "10px" }}>
                              {dataContactInfo.notes && dataContactInfo.notes.length > 0 ? (
                                dataContactInfo.notes.map((note, k) => (
                                  <li key={k} className="py-1 d-flex justify-content-between pointer span-color">
                                    <div>- {note.note.length >= 35
                                        ? note.note.substr(0, 35) + "..."
                                        : note.note
                                    }</div>
                                    <div className="d-flex">
                                      <FaEye className="icon-pencil mr-2" title="Ver" style={{ fontSize: "15px" }} onClick={() => viewNote(note)}/>
                                      <BsPencilFill className="icon-pencil mr-2" title="Editar" style={{ fontSize: "15px" }} onClick={() => getNote(note.id)}/>
                                      <BsTrash3 className="icon-trash-empty" title="Eliminar" style={{ fontSize: "15px" }} onClick={() => deleteNote(note.id)}/>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <div className="span-color text-center">
                                  No hay notas
                                </div>
                              )}
                            </ul>
                          </div>
                          {addNewNote ? (
                            <form>
                              <Div className="col-md-12">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable span-color">
                                  <label htmlFor="phone">{`${modeEdit ? 'Editar' : 'Agregar'} nota:`}</label>
                                  <div className="su-control-group">
                                    {loadingGetNote ? 
                                      <div className="loading-send-template d-flex justify-content-center align-items-center">
                                        <span style={{ padding: "12px" }}>
                                            <FaSpinner className="fa-spin" style={{ fontSize: "25px" }} />
                                        </span>
                                    </div>
                                    :
                                      <textarea
                                        name="note"
                                        className="form-control"
                                        id="note"
                                        {...register("note")}
                                        onChange={(e) => setNewNote(e.target.value)}
                                      ></textarea>
                                    }
                                  </div>
                                </div>
                              </Div>
                              <div className="text-right pr-2">
                                <a
                                  style={{ fontSize: "0.89em" }}
                                  href="javascript:;"
                                  className="btn btn-lg btn-default mr-1"
                                  onClick={() => {
                                    setModeEdit((prevMessage) => { return false});
                                    setAddNewNote((prevMessage) => { return false});
                                  }}
                                >
                                  Cancelar
                                </a>
                                <input
                                  style={{ fontSize: "0.89em" }}
                                  className="btn btn-lg btn-info"
                                  value={modeEdit ? "Actualizar" : "Guardar"}
                                  name="submit"
                                  id="submit"
                                  type="button"
                                  onClick={() => modeEdit ? putNote() : postNote()}
                                />
                              </div>
                            </form>
                          ) : (
                            <div className="d-flex justify-content-center">
                              <button
                                type="button"
                                className="btn btn-link btn-link-blue btn-toggle btn-personalize mb-1 d-flex"
                                onClick={() => {
                                  setAddNewNote(true);
                                  setModeEdit(false)
                                  reset({ note: "" });
                                }}
                              >
                                <BsPlusSquareDotted className="mr-1" style={{ fontSize: "16px" }}/>
                                Agregar nota
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    {/* Listas */}
                    <div className="segments">
                      <h5
                        className="pointer d-flex justify-content-between"
                        onClick={() => {
                          setTagsSection(false);
                          setDataSection(false);
                          setNotesSection(false);
                          setResponsibleSection(false);
                          setOrdersSection(false);
                          setListsSection(prev => !prev);
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <BsListStars className="mr-1"/>
                          Listas
                        </div>
                        {listsSection ?
                          <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                          <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                        }
                      </h5>
                      {listsSection && (
                        <form>
                          <Div className="col-md-12">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                              <div className="su-control-group">
                                <Select
                                  styles={isDark == 'true' ? customStylesDark : customStyles}
                                  className="form-control basic-multi-select"
                                  isMulti
                                  value={dataContactInfo.lists}
                                  placeholder="Seleccione..."
                                  name="tags"
                                  onChange={handleChangeList}
                                  options={allLists}
                                  classNamePrefix="select"
                                  noOptionsMessage="Sin opciones"
                                  getOptionLabel={(option) => (
                                    <div className="option d-flex options-list-multi" style={{fontSize: "15px"}}>
                                      {option.name}
                                    </div>
                                  )}
                                  getOptionValue={(option) => option._id}
                                  isDisabled={isDisableSelect}
                                />
                              </div>
                            </div>
                          </Div>
                          {addNewSegment ? (
                            <div className="text-right pr-2 mt-3">
                              <a
                                href="javascript:;"
                                style={{ fontSize: "0.89em" }}
                                className="btn btn-lg btn-default mr-1"
                                onClick={() => {
                                  setAddNewSegment((prevMessage) => { return false});
                                  setIsDisableSelect((prevMessage) => { return true});
                                }}
                              >
                                Cancelar
                              </a>
                              <input
                                style={{ fontSize: "0.89em" }}
                                className="btn btn-lg btn-info"
                                value="Guardar"
                                name="submit"
                                id="submit"
                                type="button"
                                onClick={() => addUserToList()}
                              />
                            </div>
                          ):(
                            <div className="d-flex justify-content-center">
                              <button
                                type="button"
                                className="btn btn-link btn-link-blue btn-toggle btn-personalize mb-1 d-flex"
                                onClick={() => {
                                  setAddNewSegment(true);
                                  setIsDisableSelect(false);
                                }}
                              >
                                <BsPencilFill className="mr-1" style={{ fontSize: "16px" }}/>
                                Agregar o eliminar lista
                              </button>
                            </div>
                          )}
                        </form>
                      )}
                    </div>
                    
                    {/* Responsable */}
                    {dataContactInfo.responsible && (
                      <div className="condition">
                        <h5
                          className="pointer d-flex justify-content-between"
                          onClick={() => {
                            setTagsSection(false);
                            setDataSection(false);
                            setNotesSection(false);
                            setListsSection(false);
                            setOrdersSection(false);
                            setResponsibleSection(prev => !prev);
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <BsFillPersonCheckFill className="mr-1"/>
                            Responsable
                          </div>
                          {responsibleSection ?
                            <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                            <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                          }
                        </h5>
                        {responsibleSection &&
                          <div className="mt-3 mb-2 text-center">{dataContactInfo.responsible}</div>
                        }
                      </div>
                    )}
                    
                    {/* Etiquetas */}
                    {dataContactInfo.tags && (
                      <div className="condition">
                        <h5
                          className="pointer d-flex justify-content-between"
                          onClick={() => {
                            setNotesSection(false);
                            setDataSection(false);
                            setListsSection(false);
                            setResponsibleSection(false);
                            setOrdersSection(false);
                            setTagsSection(prev => !prev)
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <FaTags className="mr-1"/>
                            Etiquetas
                          </div>
                          {tagsSection ?
                            <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                            <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                          }
                        </h5>
                        {tagsSection &&
                          <div className="mt-3 mb-2 text-center">
                            <ul className="justify-content-center" style={{display:"grid"}}>
                              {dataContactInfo.tags && dataContactInfo.tags.length > 0 ? (
                                dataContactInfo.tags.map((tag, t) => (
                                  <li key={t} className="m-1 px-2 py-1" style={{backgroundColor: tag.color, color: "#fff", borderRadius: "6px"}}>
                                    <span style={{fontWeight: "600"}}>{tag.title}</span>
                                  </li>
                                ))
                              ) : (
                                <li className="span-color">No hay etiquetas</li>
                              )}
                            </ul>

                          </div>
                        }
                      </div>
                    )}

                    {/* Ordenes */}
                    {ordersFilteredInfo && ordersFilteredInfo.length > 0 && (
                      <div className="condition">
                        <h5
                          className="pointer d-flex justify-content-between"
                          onClick={() => {
                            setNotesSection(false);
                            setDataSection(false);
                            setListsSection(false);
                            setResponsibleSection(false);
                            setTagsSection(false)
                            setOrdersSection(prev => !prev)
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <TiShoppingCart className="mr-1"/>
                            Pedidos
                          </div>
                          {ordersSection ?
                            <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                            <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                          }
                        </h5>
                        {ordersSection &&
                          <div className="mt-3 mb-2 text-center">
                            <ul className="justify-content-center" style={{display:"grid"}}>
                              {
                                ordersFilteredInfo.slice(0,8).map((order) => (
                                  <li key={order._id} className="m-1 px-2 py-1">
                                    <Link to={`/home/order/${order._id}`} className='span-color d-flex align-items-center px-1'>
                                      <FaShoppingBasket className="mr-1"/> Pedido N° {order.id} por {order.total}
                                    </Link>
                                  </li>
                                ))
                              }
                              {
                                ordersFilteredInfo.length > 8 && (
                                  <li className='mx-2 px-2' style={{fontWeight: "700"}}>
                                    <Link to={`/home/orders`} className='text-info MuiButton-textPrimary'>
                                      Ver más...
                                    </Link>
                                  </li>
                                )
                              }
                            </ul>
                          </div>
                        }
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
      </div>

      {/* Modal para mostrar la información de las notas */}
      <Modal
        status={modalViewNote}
        changeStatus={setModalViewNote}
        title= "Información de la nota"
        width={"600px"}
      >
        <Content>
          <form style={{ width: "100%" }}>
            <div>
              <div className="su-control mt-2 text-center">
                <ul>
                  <li>
                    <h4>Creada por:</h4>
                    <p>{noteSelected.agent}</p>
                  </li>
                  <li>
                    <h4>Creada el:</h4>
                    <p>
                    {dayjs(noteSelected.date)
                      .locale("es")
                      .format("DD MMMM YYYY - HH:mm a")}</p>
                  </li>
                  <li>
                    <h4>Contenido:</h4>
                    <p>{noteSelected.note}</p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalViewNote((prevMessage) => { return false});
                }}
              >
                Cerrar
              </a>
            </div>
          </form>


        </Content>
      </Modal>
    </Fragment>
  );
};


export default Prospects;

const IconList = styled.div`
  margin-bottom: 0px;

  div {
    height: 37px;
    width: 37px;
    border-radius: 50%;
    // box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.7);
    margin: 4px auto;
  }

  p {
    font-size: 18px;
    font-weight: 600;
  }
`;
const Botton = styled.button`
  color: #000000;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.3;

  &:hover {
    color: #000000;
    opacity: 0.7;
  }
`;
const Icon = styled.div`
  margin-bottom: 20px;

  div {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    // box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.7);
    margin: 0 auto;
  }

  p {
    font-size: 40px;
    font-weight: 600;
  }
`;
const Div = styled.div`
  label {
    font-size: 14px;
  }

  input,
  textarea {
    font-size: 1em;
  }
`;
const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 85px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  .content-img img:hover {
    opacity: 0.6;
  }
`;
