import React, { useState } from 'react';
import { IoMdDownload } from "react-icons/io";
import WarningMessage from './typeMessages/WarningMessage';
import TransferMessage from './typeMessages/TransferMessage';
import ImageOrStickerMessage from './typeMessages/ImageOrStickerMessage';
import LocationOrForwardedMessage from './typeMessages/LocationOrForwardedMessage';
import ContactsOrForwardedMessage from './typeMessages/ContactsOrForwardedMessage';
import VideoOrForwardedMessage from './typeMessages/VideoOrForwardedMessage';
import DocumentOrForwardedMessage from './typeMessages/DocumentOrForwardedMessage';
import AudioOrForwardedMessage from './typeMessages/AudioOrForwardedMessage';
import TemplateMessage from './typeMessages/TemplateMessage';
import InteractiveMessage from './typeMessages/InteractiveMessage';
import OrderMessage from './typeMessages/OrderMessage';
import ContextProductMessage from './typeMessages/ContextProductMessage';
import ContextTextMessage from './typeMessages/ContextTextMessage';
import ForwardedMessage from './typeMessages/ForwardedMessage';
import AdvertisingMessage from './typeMessages/AdvertisingMessage';
import TextMessage from './typeMessages/TextMessage';
import FooterMessage from './typeMessages/FooterMessage';
import ListButtonsCallToAction from './typeMessages/ListButtonsCallToAction';
import GroupedImages from './typeMessages/GroupedImages';
import ImageGallery from './ImageGallery';
import CloseConvMessage from './typeMessages/CloseConvMessage';

const MessageListContents = ({
    listMessages,
    setListMessages,
    templates,
    imgMap,
    setListContacts,
    setModalListContacts,
    showContactInfo,
    videoSelected,
    listProducts,
    formGenerateLink,
    onViewMenssageContext,
    setLoading,
    onViewImage,
    setModalViewProductContext,
    setProductContextSelected,
    setModalViewOrder,
    setQuantityOrder,
    setTotalPriceOrder,
    setOrderSelected
}) => {
    const [isGallery, setIsGallery] = useState(false)
    const [imgSelected, setImgSelected] = useState("")
    const [listImages, setListImages] = useState("")

    const onViewGalery = async (gallery) => {
        setIsGallery(true)
        setImgSelected(gallery[0])
        setListImages(gallery)
        console.log('lista de imagenes:::', gallery)
    }
  return (
    <>
        <section className="section-preview msg p-3 pt-4">
            {listMessages && listMessages.map((message, m) => {
                var ext = "";
                const namee = message.nameMedia;
                if (namee) {
                    var extension = message.nameMedia.split(".")[1];
                    if (extension) {
                        ext = extension;
                    }
                }
                //Validamos si el tipo de mensaje para obtener y pintar su contenido
                const typeMessage = message.bodyType;
                var formatHeader = "";
                var headerText = ""
                var bodyText = "";
                var footerText = "";
                var bodyTextFlow = "";
                var listButtonsCallToAction = [];
                var listButtonsQuickReply = [];
                var listButtonsUrlDynamic = [];
                let imgProduct = "";
                let numberProducts = "";
                let linkLocation = ""
                let nameLocation = ""
                let linkName = ""
                let adressLocation = ""
                let textContacts = ""
                let contactList = []
                let orderShopping = []
                let nameProductContext = ""
                let ProductInContext = {}
                let isSalePrice = false
                let salePrice = ""
                let price = ""
                let buttonsReply = []
                let bodyTextContext = ""
                let textContext = ""
                let agentAsignMsj = ""
                let messageContextType = ""
                let textImgContext = ""
                let messageIdContext = ""
                let imgAdvertising = ""
                let urlAdvertising = ""
                let typeForwarded = ""
                let textForwarded = ""
                let isTextError = false
                let typeResponseUser = ""


                if (typeMessage === "template") {
                    const idTemplatee = parseInt(message.idTemplate);
                    if (idTemplatee && templates.length > 0) {
                        const filteredTemplates = templates.filter(tem => tem.id === idTemplatee);

                        if (filteredTemplates.length > 0) {
                            //#--> Validamos que si tenga header la plantilla
                            const filterHeader = filteredTemplates[0].content.filter(tem => {if (tem.type === "HEADER") return tem});
                            if(filterHeader.length){
                                formatHeader = filterHeader[0].format;
                                headerText = filterHeader[0].text;
                            }

                            //#--> Validamos el Body de la plantilla
                            const filterBody = filteredTemplates[0].content.filter(tem => {if (tem.type === "BODY") return tem})
                            bodyText = filterBody[0].text;
                            if(filterBody.length){
                                bodyText = filterBody[0].text;
                            }
                            //#--> Validamos que si tenga footer la plantilla
                            const contentFooter = filteredTemplates[0].content.filter(tem => {if (tem.type === "FOOTER") return tem})
                            footerText = contentFooter;

                            //#--> Validamos que si tenga botones la plantilla selecionada
                            const filterButtons = filteredTemplates[0].content.filter(tem => {if (tem.type === "BUTTONS") return tem});

                            if (filterButtons.length != 0) {
                            const listButtons = filterButtons[0].buttons;

                            for (let b = 0; b < listButtons.length; b++) {
                                const element = listButtons[b];
                                if (element.type == "URL") {
                                    listButtonsCallToAction.push(element);
                                    if (element.example) {
                                        listButtonsUrlDynamic.push(element);
                                    }
                                } else if (element.type == "PHONE_NUMBER") {
                                    listButtonsCallToAction.push(element);
                                } else if (element.type == "QUICK_REPLY") {
                                    listButtonsQuickReply.push(element);
                                } else if (element.type == "FLOW") {
                                    listButtonsCallToAction.push(element);
                                }
                            }
                            }
                        } else {
                            if (ext) {
                                if (ext == "jpg" || ext == "jpeg" || ext == "png") {
                                    formatHeader = "IMAGE"
                                } else if (ext == "mp4") {
                                    formatHeader = "VIDEO"
                                } else if (ext == "pdf" || ext == "docx" || ext == "doc" || ext == "csv" || ext == "xlsx" || ext == "txt" || ext == "xls") {
                                    formatHeader = "DOCUMENT"
                                }
                            }
                            bodyText = "Mensaje tipo plantilla."
                        }
                    }
                }

                if (typeMessage === "interactive") {
                    // console.log('-----------------------🚩:', message)
                    const contentInteractive = JSON.parse(message.bodytext)
                    let texOfButton = "";
                    let buttonType = 1;
                    for (let cont of contentInteractive) {
                        if ("header" in cont) {
                            formatHeader = cont.header.type
                            if (formatHeader === "text") {
                                headerText = cont.header.text
                            }
                        }
                        if ("body" in cont) {
                            bodyTextFlow = cont.body.text
                        }
                        if ("footer" in cont) {
                            footerText = cont.footer.text
                        }
                        if ("button" in cont) {
                            texOfButton = cont.button
                        }
                        if ("interactiveType" in cont) {
                            if (cont.interactiveType == "product_list") {
                                buttonType = 2
                            }
                        }
                        if ("productsId" in cont) {
                            const filterProduct = listProducts.filter(product => product.retailer_id == cont.productsId[0])
                            imgProduct = filterProduct[0] ? filterProduct[0].image_url : message.bodyMedia
                            numberProducts = cont.productsId.length;
                        }
                        if ("type" in cont) {
                            if (cont.type == "list") {
                                buttonType = 3
                            } else if (cont.type == "button") {
                                buttonType = 4
                            } else if (cont.type == "location_request_message") {
                                buttonType = 5
                            }
                        }
                        if ("buttons" in cont) {
                            buttonsReply = cont.buttons
                        }
                    }

                    let objButton = {}
                    if (buttonType === 2) {
                        objButton.type = "catalog"
                        objButton.text = "Ver artículos"
                    } else if (buttonType === 3) {
                        objButton.type = ""
                        objButton.text = texOfButton
                    } else if (buttonType === 5) {
                        objButton.type = "send_location"
                        objButton.text = "Enviar ubicación"
                    } else {
                        objButton.type = "flow"
                        objButton.text = texOfButton
                    }
                    if (buttonType === 4) {
                        listButtonsQuickReply = buttonsReply;
                    } else {
                        listButtonsCallToAction.push(objButton)
                    }
                }

                if (typeMessage === "location") {
                    let objButton = {}
                    objButton.type = "location"
                    objButton.text = "Ver ubicación"
                    listButtonsCallToAction.push(objButton)

                    let dataLocation = message.bodytext.split("||")
                    linkLocation = dataLocation[0]
                    nameLocation = dataLocation[1]
                    if (dataLocation[3]) {
                        linkName = dataLocation[2]
                        adressLocation = dataLocation[3]
                    } else {
                        linkName = dataLocation[0]
                        adressLocation = dataLocation[2]
                    }
                }

                if (typeMessage === "contacts") {
                    contactList = JSON.parse(message.bodytext);
                    if (contactList.length > 1) {
                        textContacts = `${contactList[0].name} y ${contactList.length - 1} contactos más`;
                        let objButton = {}
                        objButton.type = "contacts"
                        objButton.text = "Ver todos"
                        listButtonsCallToAction.push(objButton)
                    } else {
                        textContacts = `${contactList[0].name}`;
                    }
                }

                if (typeMessage === "order") {
                    const data = JSON.parse(message.bodytext)
                    let totalProducts = 0;
                    let totalPrice = 0;
                    for (let prod of data) {
                        totalProducts = totalProducts + prod.quantity
                        const priceTotalProduct = prod.quantity * prod.item_price
                        totalPrice = totalPrice + priceTotalProduct
                    }
                    numberProducts = totalProducts;
                    headerText = "$ "+totalPrice.toLocaleString('es-CO') + " " + "(total estimado)";

                    const filterProduct = listProducts.find(product => product.retailer_id == data[0].product_retailer_id )
                    imgProduct = filterProduct ? filterProduct.image_url : message.bodyMedia

                    let objButton = {}
                    objButton.type = "order"
                    objButton.text = "Ver orden"
                    listButtonsCallToAction.push(objButton)

                    orderShopping = data;

                }

                if (typeMessage === "contextProduct") {
                    const productContext = JSON.parse(message.bodytext)
                    const filterProduct = listProducts.find(product => product.retailer_id == productContext.product_retailer_id);
                    ProductInContext = filterProduct

                    imgProduct = filterProduct?.image_url
                    nameProductContext = filterProduct?.name
                    if (filterProduct?.sale_price) {
                        isSalePrice = true
                        salePrice = filterProduct.sale_price.replace('$','').trim()
                        price = filterProduct.price.replace('$','').trim()
                    } else {
                        isSalePrice = false
                        price = filterProduct?.price.replace('$','').trim()
                    }
                    bodyTextFlow = productContext.text

                    let objButton = {}
                    objButton.type = "productContext"
                    objButton.text = "Ver producto"
                    listButtonsCallToAction.push(objButton)

                }

                if (typeMessage === "contextText") {
                    const contextText = JSON.parse(message.bodytext)
                    typeResponseUser = contextText.typeResponseUser ?? ""
                    messageContextType = contextText.messageType;
                    textContext = contextText.messageContext;
                    messageIdContext = contextText.messageId;
                    if (messageContextType == "imageText") {
                        const objImg = JSON.parse(textContext)
                        textImgContext = objImg.text
                        textContext = objImg.url
                    } else if (messageContextType == "image" || messageContextType == "documentText" || messageContextType == "document" || messageContextType == "videoText" || messageContextType == "video" || messageContextType == "audioText" || messageContextType == "audio") {
                        textContext = contextText.messageContext
                    }

                    agentAsignMsj = contextText.agentAsign

                    if (contextText.typeResponseUser == "contacts") {
                        contactList = JSON.parse(contextText.messageText);
                        if (contactList.length > 1) {
                            textContacts = `${contactList[0].name} y ${contactList.length - 1} contactos más`;
                            let objButton = {}
                            objButton.type = "contacts"
                            objButton.text = "Ver todos"
                            listButtonsCallToAction.push(objButton)
                        } else {
                            textContacts = `${contactList[0].name}`;
                        }
                        bodyTextContext = ""
                    } else if (contextText.typeResponseUser == "location") {
                        let dataLocation = contextText.messageText.split("||")
                        linkLocation = dataLocation[0]
                        nameLocation = dataLocation[1]
                        linkName = dataLocation[2]
                        adressLocation = dataLocation[3]

                        let objButton = {}
                        objButton.type = "location"
                        objButton.text = "Ver ubicación"
                        listButtonsCallToAction.push(objButton)

                        bodyTextContext = ""
                    } else {
                        bodyTextContext = contextText.messageText
                    }

                }

                if (typeMessage === "advertising") {
                    const content = JSON.parse(message.bodytext)
                    console.log(content)
                    const cont = content.referral;
                    textContext = cont.body ?? "Publicidad."
                    imgAdvertising = cont.media_type ? (cont.media_type == "image" ? cont.image_url : cont.media_type == "video" ? cont.thumbnail_url : "") : ""
                    bodyTextContext = content.body
                    agentAsignMsj = cont.headline ? cont.headline : "Publicidad."
                    urlAdvertising = cont.source_url ? cont.source_url : ""

                    let objButton = {}
                    objButton.type = "advertising"
                    objButton.text = "Ver publicidad"
                    listButtonsCallToAction.push(objButton)
                }

                if (typeMessage === "forwarded") {
                    const msj = JSON.parse(message.bodytext)
                    typeForwarded = msj.type
                    textForwarded = msj.content
                    
                    if (typeForwarded === "contacts") {
                        contactList = msj.contacts;
                        if (contactList.length > 1) {
                            textContacts = `${contactList[0].name} y ${contactList.length - 1} contactos más`;
                            let objButton = {}
                            objButton.type = "contacts"
                            objButton.text = "Ver todos"
                            listButtonsCallToAction.push(objButton)
                        } else {
                            textContacts = `${contactList[0].name}`;
                        }
                    } else if (typeForwarded === "location") {
                        let objButton = {}
                        objButton.type = "location"
                        objButton.text = "Ver ubicación"
                        listButtonsCallToAction.push(objButton)
                        
                        let dataLocation = textForwarded.split("||")
                        linkLocation = dataLocation[0]
                        nameLocation = dataLocation[1]
                        linkName = dataLocation[2]
                        adressLocation = dataLocation[3]
                    }
                }
                
                if (typeMessage === "text") {
                    if (message.bodytext == "Este usuario esta tratando de reenviar un tipo de mensaje que no es soportado actualmente por Whatsapp Business Api. Puedes pedirle al usuario que te envie el mensaje directamente.") {
                        isTextError = true
                    }
                }
            
            return (
                <div key={m} id={message._id}>
                    {typeMessage == "warning" ? (
                        <WarningMessage message = {message}/>
                    ) : typeMessage == "transfer" ? (
                        <TransferMessage message={message} />
                    ) : typeMessage == "close_conv" ? (
                        <CloseConvMessage message={message} />
                    ) : (
                        <div className={ message.originMessage === "user" ? "section-div1 ml-2 mr-2" : "section-div1 ml-2 mr-2 text-right"}>
                            <div 
                                id={`message-${message.messageId}`} 
                                className={`section-div2 ${showContactInfo ? 'is-show-info' : ""} ${typeMessage === "groupedImages" ? 'cont-grouped-images' : ""}`} 
                                style={{
                                    maxWidth: typeMessage === "template" || 
                                    typeMessage === "interactive" ? "50%" : 
                                    typeMessage === "video" ? "80%" : 
                                    typeMessage === "groupedImages" ? "40%" :
                                    typeResponseUser == "audio" ? "30%" : "65%",
                                    minWidth: "15rem" 
                                }}
                            >
                                <div
                                    className={message.originMessage === "user" ? "section-div3 received" : "section-div3 send"}
                                    style={{background: typeMessage === "sticker" && "transparent", boxShadow: typeMessage === "sticker" && "none"}}
                                    id={typeMessage === "sticker" && "isSticker"}
                                >
                                    <div className={message.originMessage === "user" ? "section-div4" : "section-div4 text-left"}>
                                        <div
                                            className={typeMessage === "location" ? "section-div5 div5-location pb-0" : typeMessage === "interactive" ? "section-div5 div5-interactive pb-0" : "section-div5 pb-0" }
                                            style={{lineHeight: typeMessage === "contacts" ? "7px" : typeMessage === "sticker" ? "0px" : typeMessage === "contextProduct" ? "10px" : null }}
                                        >
                                            <span className={typeMessage === "text" ? "span message-text" : "span"} style={{lineHeight: typeMessage === "contacts" ? "7px" : typeMessage === "interactive" ? "0px" : null}}>
                                                {(typeMessage === "image" || typeMessage === "sticker") || (typeMessage === "forwarded" && (typeForwarded === "image" || typeForwarded === "sticker")) ? (
                                                    <ImageOrStickerMessage 
                                                        message = {message}
                                                        typeForwarded = {typeForwarded}
                                                        textForwarded = {textForwarded}
                                                        onViewImage = {onViewImage}
                                                        onViewGalery = {onViewGalery}
                                                    />
                                                ) : typeMessage === "location" || (typeMessage === "forwarded" && typeForwarded == "location") ? (
                                                    <LocationOrForwardedMessage
                                                        message = {message}
                                                        linkLocation = {linkLocation}
                                                        imgMap = {imgMap}
                                                        nameLocation = {nameLocation}
                                                        linkName = {linkName}
                                                        adressLocation = {adressLocation}
                                                    />
                                                ) : typeMessage === "contacts" || (typeMessage === "forwarded" && typeForwarded == "contacts") ? (
                                                    <ContactsOrForwardedMessage 
                                                        message = {message}
                                                        contactList = {contactList}
                                                        setListContacts = {setListContacts}
                                                        setModalListContacts = {setModalListContacts}
                                                        textContacts = {textContacts}
                                                    />
                                                ) : typeMessage === "video" || (typeMessage === "forwarded" && typeForwarded == "video") ? (
                                                    <VideoOrForwardedMessage 
                                                        message = {message} 
                                                        textForwarded = {textForwarded}
                                                    />
                                                ) : typeMessage === "document" || (typeMessage === "forwarded" && typeForwarded == "document") ? (
                                                    <DocumentOrForwardedMessage 
                                                        showContactInfo = {showContactInfo}
                                                        message = {message}
                                                        ext = {ext}
                                                        textForwarded = {textForwarded}
                                                    />
                                                ) : typeMessage === "audio" || (typeMessage === "forwarded" && typeForwarded == "audio") ? (
                                                    <AudioOrForwardedMessage message={message} />
                                                ) : typeMessage === "template" ? (
                                                    <TemplateMessage
                                                        message = {message}
                                                        formatHeader = {formatHeader}
                                                        headerText = {headerText}
                                                        videoSelected = {videoSelected}
                                                        onViewImage = {onViewImage}
                                                        setLoading = {setLoading}
                                                        listMessages  = {listMessages}
                                                        setListMessages  = {setListMessages}
                                                    />
                                                ) : typeMessage === "interactive" ? (
                                                    <InteractiveMessage
                                                        message = {message}
                                                        formatHeader = {formatHeader}
                                                        headerText = {headerText}
                                                        imgProduct = {imgProduct}
                                                        showContactInfo = {showContactInfo}
                                                        numberProducts = {numberProducts}
                                                        ext = {ext}
                                                        bodyTextFlow = {bodyTextFlow}
                                                        onViewImage = {onViewImage}
                                                    />
                                                ) : typeMessage === "order" ? (
                                                    <OrderMessage
                                                        message = {message}
                                                        headerText = {headerText}
                                                        imgProduct = {imgProduct}
                                                        numberProducts = {numberProducts}
                                                        orderShopping = {orderShopping}
                                                        listProducts = {listProducts}
                                                        formGenerateLink = {formGenerateLink}
                                                        setModalViewOrder = {setModalViewOrder}
                                                        setQuantityOrder = {setQuantityOrder}
                                                        setTotalPriceOrder = {setTotalPriceOrder}
                                                        setOrderSelected = {setOrderSelected}
                                                    />
                                                ) : typeMessage === "contextProduct" ? (
                                                    <ContextProductMessage 
                                                        message = {message}
                                                        bodyTextFlow = {bodyTextFlow}
                                                        imgProduct = {imgProduct}
                                                        ProductInContext = {ProductInContext}
                                                        nameProductContext = {nameProductContext}
                                                        isSalePrice = {isSalePrice}
                                                        salePrice = {salePrice}
                                                        price = {price}
                                                        setModalViewProductContext = {setModalViewProductContext}
                                                        setProductContextSelected = {setProductContextSelected}
                                                    />
                                                ) : typeMessage === "contextText" ? (
                                                    <ContextTextMessage 
                                                        message = {message}
                                                        messageIdContext = {messageIdContext}
                                                        messageContextType = {messageContextType}
                                                        showContactInfo = {showContactInfo}
                                                        textImgContext = {textImgContext}
                                                        textContext = {textContext}
                                                        agentAsignMsj = {agentAsignMsj}
                                                        typeResponseUser = {typeResponseUser}
                                                        contactList = {contactList}
                                                        textContacts = {textContacts}
                                                        linkLocation = {linkLocation}
                                                        imgMap = {imgMap}
                                                        nameLocation = {nameLocation}
                                                        linkName = {linkName}
                                                        adressLocation = {adressLocation}
                                                        ext = {ext}
                                                        bodyTextContext = {bodyTextContext}
                                                        setListContacts = {setListContacts}
                                                        setModalListContacts = {setModalListContacts}
                                                        onViewMenssageContext = {onViewMenssageContext}
                                                        onViewImage = {onViewImage}
                                                    />
                                                ) : typeMessage === "forwarded" ? (
                                                    <ForwardedMessage textForwarded = {textForwarded}/>
                                                ) : typeMessage === "groupedImages" ? (
                                                    <GroupedImages 
                                                        message = {message}
                                                        onViewGalery = {onViewGalery}
                                                    />
                                                ) : typeMessage === "advertising" ? (
                                                    <AdvertisingMessage 
                                                        message = {message}
                                                        imgAdvertising = {imgAdvertising}
                                                        agentAsignMsj = {agentAsignMsj}
                                                        textContext = {textContext}
                                                        bodyTextContext = {bodyTextContext}
                                                    />
                                                ) : typeMessage === "transfer" ? (
                                                    <span style={{ color: "#5bc0de"}}>{message.bodytext}</span>
                                                ) : (
                                                    <TextMessage message={message} isTextError={isTextError}/>
                                                )}
                                            </span>
                                        </div>
                                        
                                        <FooterMessage message={message} footerText={footerText} />
            
                                        {typeMessage === "image" || typeMessage === "video" ? (
                                            <a href={message.bodyMedia} target="_blank" download="sendingup-image.jpg" rel="noreferrer">
                                                <span id="downOpen" className="pointer" title="Descargar" onClick={(e) => e.stopPropagation()}>
                                                    <IoMdDownload style={{fontSize: "1.8rem"}}/>
                                                </span>
                                            </a>
                                        ) : null}
            
                                    </div>
            
                                    {/* LISTA DE BOTONES DE LLAMADA A LA ACCIÓN */}
                                    {listButtonsCallToAction && listButtonsCallToAction.map((btn, b) => (
                                        <ListButtonsCallToAction 
                                            b = {b}
                                            btn = {btn}
                                            message = {message}
                                            contactList = {contactList}
                                            orderShopping = {orderShopping}
                                            ProductInContext = {ProductInContext}
                                            listProducts = {listProducts}
                                            urlAdvertising = {urlAdvertising}
                                            linkLocation = {linkLocation}
                                            setListContacts = {setListContacts}
                                            setModalListContacts = {setModalListContacts}
                                            setModalViewProductContext = {setModalViewProductContext}
                                            setProductContextSelected = {setProductContextSelected}
                                            setModalViewOrder = {setModalViewOrder}
                                            setQuantityOrder = {setQuantityOrder}
                                            setTotalPriceOrder = {setTotalPriceOrder}
                                            setOrderSelected = {setOrderSelected}
                                            setLoading = {setLoading}
                                            formGenerateLink = {formGenerateLink}
                                        />
                                    ))}
            
                                </div>
            
                                {/* LISTA DE BOTONES DE RESPUESTA RAPIDA */}
                                {listButtonsQuickReply && listButtonsQuickReply.map((btn, b) => (
                                    <div key={b} className="content-fastAnswer message">
                                        <div>
                                            <span>{btn.text}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )})}
        </section>
        
        {isGallery && (
            <ImageGallery 
                imgSelected = {imgSelected}
                listImages = {listImages}
                setIsGallery = {setIsGallery}
                setImgSelected = {setImgSelected}
            />
        )}
    </>
  );
};

export default MessageListContents;